import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { createColumnHelper } from "@tanstack/react-table";
import authService from "../../api-authorization/AuthorizeService";
import RequestOverlay from "../../components/RequestOverlay";
import Table from "../../components/Table";
import styles from "./AdminConfigClients.module.css";
import { useNavigate } from "react-router-dom";

const columnHelper = createColumnHelper();

const AdminConfigClients = () => {
  const [clients, setClients] = useState([]);
  const [, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    primaryContactEmail: "",
    primaryContactPhone: "",
  });
  const [client, setClient] = useState("");

  const handleFormChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => info.getValue(),
        header: () => <span>Client Name</span>,
      }),
      columnHelper.accessor("primaryContactEmail", {
        cell: (info) => <i>{info.getValue() ?? "N/A"}</i>,
        header: () => <span>E-mail</span>,
      }),
      columnHelper.accessor("primaryContactPhone", {
        cell: (info) => <i>{info.getValue() ?? "N/A"}</i>,
        header: () => <span>Phone Number</span>,
      }),
      columnHelper.accessor("actions", {
        cell: (info) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to={`/admin/clients/${info.row.original.id}`}
              style={{ textDecoration: "none" }}
            >
              Edit
            </Link>
            <button
              onClick={() => handleDisable(info)}
              style={{ marginLeft: "8px" }}
            >
              Disable
            </button>
          </div>
        ),
        header: () => <span>Actions</span>,
      }),
    ],
    []
  );

  const fetchClients = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/clients/all`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const clientData = await response.json();
      setClients(clientData);
      setLoading(false);
      setError(false);
    } catch (error) {
      console.log("error", error);
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleDisable = async (info) => {
    console.log("info", info);

    if (
      window.confirm(
        `Are you sure you want to disable client: ${info.row.original.name}?`
      )
    ) {
      console.log("disabled!");
    } else {
      console.log("cancelled!");
    }
  };

  const handleAddClient = async () => {
    if (
      !formData.name ||
      !formData.primaryContactPhone ||
      !formData.primaryContactEmail
    )
      return toast.error("Please complete all required fields.");
    const token = await authService.getAccessToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/clients/add`,
        {
          method: "POST",
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
          body: JSON.stringify({
            ...formData,

            // name: formData.name,
            // primaryContactEmail: formData.primaryContactEmail,
            // primaryContactPhone: formData.primaryContactPhone
          }),
        }
      );

      toast.success("Client added successfully!");
      setClient("");
      setFormData({
        name: "",
        primaryContactEmail: "",
        primaryContactPhone: "",
      });

      fetchClients();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={!clients}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />

        <section style={{ zIndex: 2 }} className="relative py-12 w-full pt-20">
          <div className="pb-14">
            <div className="w-full my-8">
              <Table
                data={clients}
                columns={columns}
                handleDisable={handleDisable}
              />
            </div>

            <div>
              <button
                className={styles.configbutton}
                onClick={() => {
                  setShowAddClient(!showAddClient);
                }}
              >
                {showAddClient ? "Hide new client form" : "New client form"}
              </button>

              {showAddClient && (
                <div className="flex flex-col">
                  <input
                    className="my-8 w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    placeholder="Name of the new client..."
                    value={formData.name}
                    onChange={handleFormChange("name")}
                  />

                  <input
                    className="my-8 w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    placeholder="Email of the new client..."
                    value={formData.primaryContactEmail}
                    onChange={handleFormChange("primaryContactEmail")}
                  />

                  <input
                    className="my-8 w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    placeholder="Phone of the new client..."
                    value={formData.primaryContactPhone}
                    onChange={handleFormChange("primaryContactPhone")}
                  />

                  <div>
                    <button
                      className={styles.configbutton}
                      onClick={handleAddClient}
                    >
                      Add New Client
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
            <main
              style={{ zIndex: 10 }}
              className="block justify-center lg:col-span-7 xl:col-span-6"
            >
              <div
                className="max-w-xl lg:max-w-3xl run-details-container"
                style={{ zIndex: 2 }}
              >
                <div className="mt-4"></div>
                <div className="mt-8 grid grid-cols-6 gap-6"></div>
              </div>
            </main>
          </div>
          <div className="grid grid-cols-2 grid-rows-1 gap-4"></div>
        </section>
      </div>
    </>
  );
};

export default AdminConfigClients;
