import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import authService from "../../api-authorization/AuthorizeService";
import styles from "./AdminConfigTractorsEdit.module.css";

const AdminConfigTractorsEdit = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    primaryContactEmail: "",
    phoneNumber: "",
  });

  const [client, setClient] = useState(null);
  const [, setLoading] = useState(true);
  const [, setError] = useState(false);

  useEffect(() => {
    const fetchClient = async () => {
      setLoading(true);
      try {
        const token = await authService.getAccessToken();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/admin/clients/${id}`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
          }
        );
        const clientData = await response.json();
        setClient(clientData);

        setFormData({
          name: clientData.name,
          // ...
        });

        setLoading(false);
        setError(false);
      } catch (error) {
        setTimeout(() => {
          setError(true);
          setLoading(false);
        }, 1000);
      }
    };
    fetchClient();
  }, [id]);


  const handleChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  return (
    <>
      <div className={styles.container}>
        {/* <RequestOverlay
          isLoading={!client}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        /> */}

        <section style={{ zIndex: 2 }} className="relative py-12 w-full pt-20">
          <h1>Tractor edit</h1>

          <input
            placeholder="field 1"
            value={client?.name}
            onChange={handleChange("name")}
          />
          <input
            placeholder="field 2"
            value={client?.primaryContactEmail}
            onChange={handleChange("primaryContactEmail")}
          />

          <input
            placeholder="field 3r"
            value={client?.primaryContactEmail}
            onChange={handleChange("phoneNumber")}
          />
        </section>
      </div>
    </>
  );
};

export default AdminConfigTractorsEdit;
