import React from "react";

import styles from "./ToDoList.module.css";

const ToDoList = () => {
  return (
    <>
      <div className={styles.container}>
        <p className="text-2xl font-medium tracking-tight text-[#031832] sm:text-4xl pb-5 text-center">
          To-Do List
        </p>
        <p className="text-xl font-medium tracking-tight text-[#031832] text-center pb-10 italic">
          This feature is coming soon...
        </p>
      </div>
    </>
  );
};

export default ToDoList;
