import React, { useMemo } from "react";
import { SWRConfig } from "swr";
import { AnimatePresence, motion } from "framer-motion";
import AuthRoute from "../src/AuthRoute";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@material-tailwind/react";
import "animate.css";
import "spinkit/spinkit.min.css";
import "react-awesome-slider/dist/styles.css";
import "./App.css";
import AppRoutes from "./AppRoutes";
import { MaterialTailwindControllerProvider } from "./components-container/pages/SettingsPages/SettingsLayout/context";
import AuthorizeRoute from "./components-container/api-authorization/AuthorizeRoute";
import Layout from "./components-container/components/Layout";
import Header from "./components-container/components/Header";
import Navbar from "./components-container/components/Navbar";
import Breadcrumbs from "./components-container/components/Breadcrumbs";
import settingsRoutes from "./components-container/pages/SettingsPages/SettingsLayout/routes";
import("preline");

const hideWrapperRoutes = ["/authentication"];

for (let i = 0; i < settingsRoutes.length; i++) {
  for (let j = 0; j < settingsRoutes[i].pages.length; j++) {
    hideWrapperRoutes.push(settingsRoutes[i].pages[j].path);
  }
}

const fetcher = (token) => async (url) => {
  let cleanUrl = url;
  if (!url.startsWith("/")) {
    cleanUrl = "/" + url;
  }

  const res = await fetch(process.env.REACT_APP_BASE_URL + cleanUrl, {
    headers: !token ? {} : { Authorization: `Bearer ${token}` },
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const Wrapper = () => {
  const location = useLocation();

  const hideWrapper = hideWrapperRoutes.some((route) =>
    location.pathname.includes(route)
  );

  if (hideWrapper && location.pathname !== "/home") return <Outlet />;

  return (
    <motion.main
      className="main__container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <Navbar />
      <Layout>
        <Breadcrumbs />
        <Header />
        <Outlet />
      </Layout>
    </motion.main>
  );
};

const App = () => {
  const location = useLocation();
  const token = useSelector((state) => state.app.token);

  const unprotected = location.pathname.includes("/authentication");
  const shouldShowLayout = !location.pathname.includes(
    "/settings/user-management"
  );

  const options = useMemo(
    () => ({
      // refreshInterval: 3000,
      fetcher: fetcher(token),
    }),
    [token]
  );

  return (
    <div id="global-container">
      <SWRConfig value={options}>
        <ThemeProvider>
          <MaterialTailwindControllerProvider>
            <Toaster
              containerClassName="always-on-top"
              position="top-right"
              reverseOrder={false}
              duration={5000}
              className="no-select always-on-top"
            />

            <AnimatePresence mode="sync">
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Wrapper />}>
                  {AppRoutes.map((route, index) => {
                    const {
                      element,
                      requireAuth,
                      requireAdmin,
                      requireManager,
                      operatorProhibitedRoute,
                      ...rest
                    } = route;
                    return (
                      <Route
                        {...rest}
                        key={index}
                        element={
                          unprotected ? (
                            element
                          ) : (
                            <AuthRoute
                              requireAdmin={requireAdmin}
                              requireManager={requireManager}
                            >
                              <AuthorizeRoute {...rest} element={element} />
                            </AuthRoute>
                          )
                        }
                      />
                    );
                  })}
                </Route>
              </Routes>
            </AnimatePresence>
          </MaterialTailwindControllerProvider>
        </ThemeProvider>
      </SWRConfig>
    </div>
  );
};

export default App;
