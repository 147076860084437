import React, { useState, useEffect, useMemo } from "react";
import useSWR, { mutate } from "swr";
import Carousel from "react-awesome-slider";
import { useParams } from "react-router-dom";
import { useInterval } from "react-use";
import RequestOverlay from "../../components/RequestOverlay";
import { format } from "../../../utils";
import { loading } from "../../../images";
import styles from "./RunDevices.module.css";

const DeviceInfo = ({ deviceProp }) => {
  if (deviceProp.percentComplete === 0)
    return (
      <div className={styles.device}>
        <h3 className={styles.title}>Device: {deviceProp.serialNumber}</h3>
        <img className="rotating" src={loading} />
      </div>
    );

  return (
    <div className={styles.device}>
      <h3 className={styles.title}>Device: {deviceProp.serialNumber}</h3>
      <div
        className={`radial-progress text-lime-700 bg-lime-400 text-white ${
          !deviceProp.isComplete && "text-yellow-700 bg-yellow-400"
        }`}
        style={{
          "--value": deviceProp.percentComplete,
          "--size": "6rem",
          "--thickness": "8px",
          marginBottom: "16px",
        }}
      >
        {deviceProp.percentComplete}
      </div>
      <br />
      <span className={styles.description}>
        {deviceProp.isComplete ? "Complete" : "In Progress"}
      </span>
    </div>
  );
};

const RunDevices = () => {
  const { runId } = useParams();
  const { data, error, isLoading } = useSWR(`/api/runs?runId=${runId}`, {
    revalidateOnFocus: false,
  });

  useInterval(() => {
    mutate(`/api/runs?runId=${runId}`);
  }, 15000);

  const formattedTime = (time) => {
    if (!time) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h" + " " + splitTime[1] + "min";
  };

  if (
    !data ||
    data.run.deviceStatuses.length === 0 ||
    data.run.runStatus === "Completed"
  )
    return null;


  return (
    <>
      <section style={{ zIndex: 2 }} className="relative py-12">
        <div className="">
          <main style={{ zIndex: 10 }} className="block justify-center">
            <div
              className="max-w-xl lg:max-w-3xl run-details-container"
              style={{ zIndex: 2 }}
            >
              <div className={styles.gridContainer}>
                {data &&
                  data.run.deviceStatuses.length > 0 &&
                  data.run.deviceStatuses.map((mappedDevice) => {
                    return (
                      <DeviceInfo
                        deviceProp={{ ...mappedDevice, endTimeUtc: data.run }}
                      />
                    );
                  })}
              </div>
            </div>
          </main>
        </div>
      </section>
    </>
  );
};

export default RunDevices;
