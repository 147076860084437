import {
  BanknotesIcon,
  BuildingStorefrontIcon,
  ChartBarIcon,
  ChartPieIcon,
  CircleStackIcon,
  HomeIcon,
  UserCircleIcon,
  TableCellsIcon,
  BellIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/solid";

const icon = {
  className: "w-5 h-5 text-inherit",
};

const routes = [
  {
    title: "General",
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: "dashboard",
        path: "/settings/dashboard",
      },
      {
        icon: <UserCircleIcon {...icon} />,
        name: "User Management",
        path: "/settings/user-management",
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: "Network",
        path: "/settings/network",
      },
      {
        icon: <BellIcon {...icon} />,
        name: "notifications",
        path: "/settings/notifications",
      },
    ],
  },
  {
    title: "analytics",
    pages: [
      {
        icon: <CircleStackIcon {...icon} />,
        name: "My Data",
        path: "/settings/my-data",
      },
      {
        icon: <ChartBarIcon {...icon} />,
        name: "Performance",
        path: "/settings/performance",
      },
      {
        icon: <ChartPieIcon {...icon} />,
        name: "Reporting",
        path: "/settings/reporting",
      },
    ],
  },
  {
    title: "Admin",
    pages: [
      {
        icon: <BuildingStorefrontIcon {...icon} />,
        name: "Shop Settings",
        path: "/settings/shop-settings",
      },
      {
        icon: <BanknotesIcon {...icon} />,
        name: "Billing / Payment",
        path: "/settings/billing",
      },
    ],
  },
  {
    title: "",
    pages: [
      {
        icon: <ArrowLeftOnRectangleIcon {...icon} />,
        name: "exit",
        path: "/home",
      },
    ],
  },
];

export default routes;
