import React, { useState, useEffect } from "react";
import { ActionIcon } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import toast from "react-hot-toast";
import authService from "../api-authorization/AuthorizeService";

const UpdateText = ({ runId, note }) => {
  const [text, setText] = useState(note);
  const [initialValue, setInitialValue] = useState(note);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setText(note);
    setInitialValue(note);
  }, [note]);

  const handleSave = async () => {
    if (initialValue === text) return;

    setLoading(true);
    const token = await authService.getAccessToken();
    const data = {
      runId: runId,
      note: text,
    };
    const requestOptions = {
      method: "PATCH",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify(data),
    };

    const fetchPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(
          `${process.env.REACT_APP_BASE_URL}/api/runs/update-note`,
          requestOptions
        )
          .then((response) => {
            if (!response.ok) {
              reject("Save failed");
              setLoading(false);
              throw new Error("Save failed");
            }

            resolve("Save successful");
            setLoading(false);
            setInitialValue(text);
          })
          .catch((error) => {
            reject("Save failed");
            setLoading(false);
            console.error("Save failed:", error);
          });
      }, 1500);
    });

    toast.promise(fetchPromise, {
      loading: "Saving...",
      success: "Save successful",
      error: "Save failed",
    });
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div className="stat note-container">
      <h1 className="rundetails-note-title">Notes</h1>
      <textarea
        value={text}
        onChange={handleChange}
        // placeholder="Type here...✏️"
        id="run-details-note-textarea"
        className="text-gray-500"
      />

      <ActionIcon
        variant="outline"
        id="save-note"
        color="white"
        size="l"
        radius="xl"
        onClick={handleSave}
        disabled={loading}
      >
        <IconCheck id="note-icon-check" size="1.75rem" />
      </ActionIcon>
    </div>
  );
};

export default UpdateText;
