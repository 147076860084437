import React, { useRef } from "react";
import SlotCounter from "react-slot-counter";
import styles from "./StatCard.module.css";
import UpdateText from "../UpdateText";
import { truncateNumber } from "../../../../src/utils";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { HoverCard, Text, Button, Popover, ActionIcon } from "@mantine/core";

function Bokeh(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={233}
      height={215}
      viewBox="0 0 233 215"
      fill="none"
      {...props}
    >
      <g
        style={{
          mixBlendMode: "soft-light",
        }}
      >
        <mask
          id="a"
          style={{
            maskType: "alpha",
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={234}
          height={215}
        >
          <rect
            x={0.95752}
            width={232.043}
            height={215}
            rx={40}
            fill="#FFB200"
          />
        </mask>
        <g mask="url(#a)" fill="#fff" fillOpacity={0.3}>
          <circle cx={200.226} cy={98.3232} r={77.3476} />
          <circle cx={122.223} cy={189.436} r={68.8262} />
        </g>
      </g>
    </svg>
  );
}

export default function StatCard({
  data,
  className,
  gridClassName = "",
  textarea,
}) {
  const [opened, { close, open }] = useDisclosure(false);
  const counterRefs = useRef([]);

  React.useEffect(() => {
    counterRefs.current.map((i) => i.startAnimation());
  }, [data]);

  return (
    <div className={`overflow-hidden ${className ?? ""}`}>
      {/* {!noBokeh && (
        <Bokeh className={styles.bokeh + " " + "slowbob-animation"} />
      )} */}
      <div className={styles.container + " " + gridClassName}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <div className={styles.statItem + " " + item.className}>
              <Popover
                width={280}
                shadow="md"
                withArrow
                style={{ position: "relative" }}
                withinPortal
                portalProps={{
                  target: "#root",
                }}
              >
                <Popover.Target className="overflow auto">
                  <div
                    className={
                      styles.statItemTitle +
                        " " +
                        item?.titleSpecialClassName || ""
                    }
                  >
                    {item.title}
                  </div>
                </Popover.Target>
                {item.tooltip && (
                  <Popover.Dropdown className="overflow-auto z-50">
                    <Text size="sm">{item.tooltip}</Text>
                  </Popover.Dropdown>
                )}
              </Popover>

              <div
                onMouseEnter={open}
                onMouseLeave={close}
                className={styles.statItemValue}
              >
                {item.animate ? (
                  <SlotCounter
                    ref={(ref) => {
                      counterRefs.current[index] = ref;
                    }}
                    animateUnchanged
                    value={truncateNumber(item.value, 1)}
                    containerClassName={`containerClassName ${item.slotClassname}`}
                    charClassName={styles.charClassName}
                    separatorClassName="separatorClassName"
                    valueClassName="valueClassName"
                  />
                ) : (
                  <div className={item.valueClassName}>
                    {truncateNumber(item.value, 1)}
                  </div>
                )}
              </div>

              <div
                className={
                  styles.statSubtitle + " " + item.statSubtitleClassName
                }
              >
                {item.subtitle}
              </div>
            </div>
          ))}

        {textarea && (
          <div
            className={
              styles.statItem +
              " " +
              styles.textArea +
              " " +
              styles.textAreaClassName
            }
          >
            <UpdateText note={textarea?.note} runId={textarea?.runId} />
          </div>
        )}
      </div>
    </div>
  );
}
