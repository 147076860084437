import React from "react";
import styles from "./NavCard.module.css";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useRef } from "react";

const DelayedLink = ({ delay, replace, state, to, ...props }) => {
  const navigate = useNavigate();
  const timerRef = useRef();
  const [animatedClass, setanimatedClass] = React.useState("");

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const clickHandler = (e) => {
    e.preventDefault();
    setanimatedClass("animate__animated animate__bounce");
    timerRef.current = setTimeout(navigate, delay, to, { replace, state });
  };

  return <Link to={to} onClick={clickHandler} {...props} />;
};

export default function NavCard({
  title,
  gradient = true,
  actionText,
  disabled,
  icon,
  link = "#",
  titleClassName = "",
  containerStyle,
  navCardContainerClassName = "",
  children,
  subContainerClassName = "",
  ...props
}) {
  return (
    <DelayedLink
      delay={200}
      to={link}
      className={`${disabled ? styles.disabled : ""} ${containerStyle || ""}`}
      {...props}
    >
      <div
        className={`${styles.container} ${
          gradient ? styles.gradient : ""
        } ${navCardContainerClassName}`}
      >
        <div className={styles.subContainer + " " + subContainerClassName}>
          {children}
          <div className={styles.imgContainer}>
            <img className={styles.image} src={icon} alt="image" />
          </div>
          {/* Add closing tag here */}
          <div>
            <h3 className={styles.title + " " + titleClassName}>{title}</h3>
          </div>
        </div>
        <button className={styles.button}>{actionText}</button>
      </div>
    </DelayedLink>
  );
}
