import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./Layout.module.css";
import { useLocation } from "react-router-dom";
import authService from "../../api-authorization/AuthorizeService";
import { setToken, setUser } from "../../../../src/store/appSlice";
import { mergeClasses } from "../../../utils";

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  console.log("pathname", pathname);

  const isMapPage = pathname.split("/").includes("run-details");
  console.log("isMapPage", isMapPage);

  useEffect(() => {
    const fetchTokenAndUser = async () => {
      const token = await authService.getAccessToken();
      const user = await authService.getUser();

      token && dispatch(setToken(token));
      user && dispatch(setUser(user));
    };

    fetchTokenAndUser();
  }, []);

  return (
    <div className={styles.applayout}>
      <div
        className={mergeClasses(
          styles.container,
          isMapPage ? styles.wideContainer : ""
        )}
      >
        {children}
      </div>
    </div>
  );
}
