import React from "react";
import UserManagementContent from "./UserManagementContent";
import SettingsLayout from "../SettingsLayout";

export default function UserManagement() {
  return (
    <SettingsLayout>
      <UserManagementContent />
    </SettingsLayout>
  );
}
