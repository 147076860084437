import { createSlice, createSelector } from "@reduxjs/toolkit";

export const runSlice = createSlice({
  name: "run",
  initialState: {
      runs: [
          /* {
              id: 1,
              markerType: "route",
              zoom: 12,
              center: { lat: 40.748817, lng: -73.985428 },
        } */
    ],
  },
  reducers: {
      addRoute: (state, action) => {
          // If route already exists, update it
          const foundRunIndex = state.runs.findIndex((run) => run.id === action.payload.id);
            if (foundRunIndex !== -1) {
                state.runs[foundRunIndex] = action.payload;
            } else {
                state.runs.push(action.payload);
            }
    
    },
      
    removeRoute: (state, action) => {
        state.runs = state.runs.filter((run) => run.id !== action.payload);
    },


  },
});


export const getAllRuns = (state) => state.runs;

export const selectRouteById = (id) => {
    return createSelector(getAllRuns, (state) => state.runs.find((run) => run.id === id));
 }


export const {
    addRoute,
    removeRoute
} = runSlice.actions;

export default runSlice.reducer;
