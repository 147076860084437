import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistReducer,
  createMigrate,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import appSlice from "./appSlice";
import runSlice from "./runSlice";
import paginationReducer from "./paginationSlice";

const reducers = combineReducers({
  app: appSlice,
  runs: runSlice,
  pagination: paginationReducer,
});

const migrations = {
  0: (state) => {
    return state;
  },
};

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["pagination"],
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // devTools: process.env.NODE_ENV !== 'production',
});

export default store;
