import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "@material-tailwind/react";
import Configurator from "./widgets/layout/configurator";
import Footer from "./widgets/layout/footer";
import Sidenav from "./widgets/layout/sidenav";
import darkLogo from "../../../../images/logo-ct-dark.png";
import lightLogo from "../../../../images/logo-ct.png";
import leafwhite from "../../../../images/leafwhite.svg";
import leafdark from "../../../../images/leafdark.png";
import agtelliologotrimmed from "../../../../images/agtelliologotrimmed.png";
import { setOpenConfigurator } from "../../../../store/appSlice";
import routes from "./routes";

const icon = {
  className: "w-5 h-5 text-inherit",
};

export function SettingsLayout({ children }) {
  const dispatch = useDispatch();
  const { sidenavType } = useSelector((state) => state.app.settingsPages);

  return (
    <div className="min-h-screen bg-blue-gray-50/50">
      <Sidenav
        className="settingsSidebar"
        routes={routes}
        brandImg={agtelliologotrimmed}
        brandName="SETTINGS"
      />
      <div className="p-4 xl:ml-80">
        <Configurator />
        <IconButton
          size="lg"
          color="white"
          className="fixed bottom-8 right-8 z-40 rounded-full shadow-blue-gray-900/10"
          ripple={false}
          onClick={() => dispatch(setOpenConfigurator(true))}
        >
          <Cog6ToothIcon className="h-5 w-5" />
        </IconButton>
        <div style={{ height: "100vh" }}>{children}</div>
        <div className="text-blue-gray-600">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default SettingsLayout;
