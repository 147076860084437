import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ActionIcon } from "@mantine/core";
import { IconUser } from "@tabler/icons-react";
import { logOut } from "../../../store/appSlice";
import styles from "./LoginAvatar.module.css";

const PLACEHOLDER =
  "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80";

export default function LoginAvatar({
  loggedIn = false,
  avatar = PLACEHOLDER,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAdmin = useSelector((state) => state.app.isAdmin);
  const isManager = useSelector((state) => state.app.isManager);
  const isOperator = useSelector((state) => state.app.isOperator);
  const isDeveloper = useSelector((state) => state.app.isDeveloper);
  const user = useSelector((state) => state.app.user);

  const handleLogOut = () => {
    dispatch(logOut());
    // navigate("/Identity/Account/Logout", {replace: true})
    window.location.href = "/Identity/Account/Logout";
  };

  if (loggedIn)
    return (
      <div className="hs-dropdown">
        <span
          id="hs-dropdown-basic"
          type="button"
          className="flex items-center gap-x-2 font-medium text-white/[.8] hover:text-white sm:border-l sm:border-white/[.3]  sm:pl-2 cursor-pointer"
        >
          <ActionIcon
            variant="filled"
            color="blue"
            size="lg"
            radius="xl"
            aria-label="Settings"
          >
            <IconUser
              style={{ width: "70%", height: "70%", marginTop: "-6px" }}
              stroke={1.5}
            />
          </ActionIcon>
          <span
            className={
              styles.text + " " + "navbarsmallelts:text-[15px] text-[12px]"
            }
            title={user?.name}
          >
            Hi {user?.name.split("@")?.[0] || "User"}!
          </span>
          <svg
            className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-white"
            style={{ marginLeft: "5px", cursor: "pointer" }}
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 5l6.16 5.687a.5.5 0 00.68 0L15 5"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg>
        </span>

        <div
          className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-95 opacity-0 w-56 hidden z-10 mt-2 min-w-[15rem] bg-blue-900 shadow-md rounded-lg p-1"
          aria-labelledby="hs-dropdown-basic"
        >
          <span className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white bg-blue-700">
            {isAdmin ? (
              <>
                {user?.name.split("@")?.[0]}:{" "}
                <span className="font-bold">Administrator</span>
              </>
            ) : isManager ? (
              <>
                {user?.name.split("@")?.[0]}:{" "}
                <span className="font-bold">Manager</span>
              </>
            ) : isDeveloper ? (
              <>
                {user?.name.split("@")?.[0]}:{" "}
                <span className="font-bold">Developer</span>
              </>
            ) : isOperator ? (
              <>
                {user?.name.split("@")?.[0]}:{" "}
                <span className="font-bold">Operator</span>
              </>
            ) : (
              <>
                {user?.name.split("@")?.[0]}:{" "}
                <span className="font-bold">User</span>
              </>
            )}
          </span>

          {isAdmin && (
            <a
              className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white hover:bg-blue-800"
              href="/admin"
            >
              Admin Configuration
            </a>
          )}
          {(isAdmin || isManager || isDeveloper) && (
            <a
              className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white hover:bg-blue-800"
              href="/settings/user-management"
            >
              User Management
            </a>
          )}
          <div className="hs-dropdown relative [--strategy:static] sm:[--strategy:absolute] [--adaptive:none] sm:[--trigger:hover]">
            {/* <button
              type="button"
              className="w-full flex justify-between items-center text-sm text-white rounded-md py-2 px-3 hover:bg-green-700"
            >
              Sub Menu
              <svg
                className="sm:-rotate-90 ml-2 w-2.5 h-2.5 text-white"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </button> */}
            <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 sm:mt-2 sm:shadow-md rounded-lg p-2 bg-green-900 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute sm:border before:-right-5 before:top-0 before:h-full before:w-5 top-0 right-full !mx-[10px]">
              <a
                className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white hover:bg-blue-800"
                href="#"
              >
                About
              </a>
              <a
                className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white hover:bg-blue-800"
                href="#"
              >
                Downloads
              </a>
              <a
                className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white hover:bg-blue-800"
                href="#"
              >
                Team Account
              </a>
            </div>
          </div>
          <span
            className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white hover:bg-blue-800 cursor-pointer"
            onClick={handleLogOut}
          >
            Logout
          </span>
        </div>
      </div>
    );

  return (
    <a
      className="flex items-center gap-x-2 font-medium text-white/[.8] hover:text-white sm:border-l sm:border-white/[.3] sm:my-6 sm:pl-6"
      href="#"
    >
      <svg
        className="w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
      </svg>
      Log in
    </a>
  );
}
