import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import to from "await-to-js";
import authService from "../../api-authorization/AuthorizeService";
import { format } from "../../../utils";
import styles from "./StartRunDialog.module.css";
import useSWR from "swr";
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export default function StartRunDialog(props) {
    const { open, onClose, tractorId} = props;
    const isDeveloper = useSelector((state) => state.app.isDeveloper);

    const [runInProgress, setRunInProgress] = useState(null);
    const [formState, setFormState] = useState({
        captureImages: true,
        dataRetentionFrequency: 1,
    });

    const [location, setLocation] = useState("");
    const [locationPermissionError, setLocationPermissionError] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingRecommended, setLoadingRecommended] = useState(true);

    const handleFormChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]:
            e.target.name === "dataRetentionFrequency"
                ? e.target.value
                : e.target.checked,
        });
    };

    const handleCancel = () => {
        onClose();
    };

    const {
        data: deviceStatus,
    } = useSWR(`/api/tractors/device-status?tractorId=${tractorId}`);

    var device_status = '';
    var device_status_color = 'text-[#8dc34d]';
    if (deviceStatus) {
        if (deviceStatus.total === deviceStatus.connected) {
            device_status = 'All';
            device_status_color = 'text-[#8dc34d]';
        } else {
            device_status = deviceStatus.connected + ' of ' + deviceStatus.total;
            device_status_color = 'text-[#b00f0f]';
        }
        device_status += ' devices connected.'
    }

    const {
        data: locations,
    } = useSWR(`/api/locations/all`);

    const getPosition = () => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    };

    const getRecommendedLocation = async (lat, lng) => {
        if (!navigator.geolocation) {
            return console.log("Geolocation is not supported by your browser");
        } else {
            const [err, position] = await to(getPosition());
            console.log("position", position);
            console.log("err", err);

            if (err) {
                setLoading(false);
                setLoadingRecommended(false);
                return setLocationPermissionError(err.message);
            }

            let lat = position.coords.latitude;
            let long = position.coords.longitude;

            const token = await authService.getAccessToken();

            try {
                const recommendedLocation = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/locations/recommended-start?lat=${lat}&long=${long}`,
                    {
                        method: "GET",
                        headers: !token
                            ? {}
                            : {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json",
                            },
                    }
                );

                const response = await recommendedLocation.json();
                setLocation(response.id);
                setLoadingRecommended(false);
            } catch (error) {
                setLoadingRecommended(false);
            }
        }
    };

    useEffect(() => {
        getRecommendedLocation();
        setLoading(false);
    }, ['tractorId']);

    const handleStart = async (event) => {
        if (!location) {
            return toast.error("Please select a location");
        }
        try {
            event.target.disabled = true;
            const token = await authService.getAccessToken();

            const devicesResponse = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/devices/all-by-tractor?tractorId=${tractorId}`,
                {
                    method: "GET",
                    headers: !token
                        ? {}
                        : {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                }
            );

            const devicesData = await devicesResponse.json();
            if (devicesData.length === 0 || !deviceStatus || deviceStatus.connected === 0)
                return toast.error("There are no devices connected to this tractor.");

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/runsOperation/start`,
                {
                    method: "POST",
                    headers: !token
                        ? {}
                        : {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    body: JSON.stringify({
                        tractorId: tractorId,
                        locationId: location,
                        dataRetentionFrequency: formState.dataRetentionFrequency,
                        captureImages: formState.captureImages,
                    }),
                }
            );

            const run = await response.json();
            onClose(`run-details/${run.runId}/${run.displayId}`);
        } catch (err) {
            toast.error(
                "An unexpected error happened! Please check your internet connectivity and try again!"
            );
            event.target.disabled = false;
        }
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleCancel}
                PaperProps={{ sx: { borderRadius: "15px", padding: "0px 10px" } }}
            >
                <DialogContent id="alert-dialog-description">
                    <h3 class={styles.title}>Start Run</h3>
                    <div className={styles.progressContainer}>
                        {runInProgress ? (
                        <p className="pb-24">
                            Run In Progress at <b>{runInProgress?.location?.name}</b> on{" "}
                            <b>
                            {runInProgress.startTimeUtc
                                ? format(
                                    runInProgress.startTimeUtc,
                                    "MMM do yyyy [h:mm aaaaa'm']"
                                )
                                : "Loading..."}
                            </b>
                        </p>
                        ) : null}
                    </div>

                    <div className={styles.addTractorRunContainer}>
                        <div className={styles.addTractorRunSelectContainer}>
                        {!deviceStatus || deviceStatus?.connected === 0 ? (
                          <div id="connecting">
                            <label className="text-center text-zinc-500 text-sm leading-tight font-bold text-[#b00f0f]">
                                Connecting to Vehicle
                            </label>
                            <CircularProgress size={15} sx={{ margin: '0 10px', verticalAlign: 'middle' }} />
                            <p className="my-1">Make sure the vehicle is started and<br/> there's power to the WiFi router.</p>
                          </div>
                        ) : '' }
                        <label className="pt-4 pb-2 font-extrabold">Location</label>
                          <div className="flex flex-row">
                            <select
                            disabled={!!runInProgress}
                            id="farm-location"
                            name="farm-location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300 mr-[10px]"
                            >
                            <option value="">-Select Location-</option>
                            {locations?.map((location) => (
                                <option key={location.id} value={location.id}>
                                {location.name}
                                </option>
                            ))}
                            </select>
                            {/* {locationPermissionError && (
                            <p className="text-sm text-red-600">
                                {locationPermissionError}
                            </p>
                            )} */}
                          </div>

                          <div className="max-w-xl lg:max-w-3xl run-details-container flex-row"
                                style={{ zIndex: 2, display: "block" }} >
                            <button id="start_run_button" disabled={!!runInProgress || !deviceStatus || deviceStatus.connected === 0} onClick={handleStart} className={styles.button} style={{ marginLeft: 0 }} >Start</button>
                            <button className={styles.button + " " + styles.cancel_button} onClick={handleCancel}>Cancel</button>
                          </div>
                        </div>
                    </div>
                    {isDeveloper && (
                        <div className={styles.addTractorRunContainer}>
                        <div className={styles.addTractorRunDeveloperContainer}>
                            <h2 className="pt-4 pb-2 font-extrabold text-lg">
                            Developer Settings:
                            </h2>

                            <div>
                            <main
                                style={{ zIndex: 10 }}
                                className={styles.startStopButtons}
                            >
                                <div
                                className="max-w-xl lg:max-w-3xl run-details-container flex"
                                style={{ zIndex: 2 }}
                                >
                                <div className="flex flex-col">
                                    <span>
                                    <label className="mr-4">Capture Photos?</label>
                                    <input
                                        name="captureImages"
                                        disabled={!!runInProgress}
                                        type="checkbox"
                                        className="my-8"
                                        defaultChecked
                                        value={formState.captureImages}
                                        onChange={handleFormChange}
                                    />
                                    </span>
                                    <span>
                                    <label className="mr-4">Data Retention Frequency</label>
                                    <input
                                        name="dataRetentionFrequency"
                                        disabled={!!runInProgress}
                                        className="my-8"
                                        placeholder="100"
                                        type="number"
                                        min="1"
                                        max="100"
                                        value={formState.dataRetentionFrequency}
                                        onChange={handleFormChange}
                                    />
                                    </span>
                                </div>
                                </div>
                            </main>
                            </div>
                        </div>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
