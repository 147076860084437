import React from "react";

const RevenueForecast = () => {
  return (
    <section>
      <p className="text-2xl font-medium tracking-tight text-black sm:text-4xl">
        Revenue Forecast
      </p>
    </section>
  );
};

export default RevenueForecast
