import React, { useState, useEffect } from "react";
import useSWR from "swr";
import SettingsLayout from "../SettingsLayout";
import styles from "./Performance.module.css";
import DashboardNavbar from "../SettingsLayout/widgets/layout/dashboard-navbar";

const Performance = () => {
  return (
    <SettingsLayout>
      <>
        <DashboardNavbar pageTitle="Performance" settingsSection="Analytics" />
        <div class="grid min-h-screen grid-cols-12 w-full">
          <main class="col-span-12 flex justify-center  sm:col-span-12 py-4">
            <div className="w-full">
              <div className={styles.container}>
                <div className="space-y-12 w-full">
                  <div className="border-b border-gray-900/10 pb-4 w-full">
                    <h2 className="font-semibold leading-7 text-gray-900 text-xl pb-4">
                      Performance Page
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    </SettingsLayout>
  );
};

export default Performance;
