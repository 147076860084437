import React, { useState, useEffect, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";
import { useDispatch, useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import { useParams, useNavigate } from "react-router-dom";
import to from "await-to-js";
import { useEffectOnce } from "react-use";
import authService from "../../api-authorization/AuthorizeService";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { ActionIcon } from "@mantine/core";
import StatCard from "../../components/StatCard";
import RequestOverlay from "../../components/RequestOverlay";
import {
  format,
  mergeClasses,
  getCoordinatesFromPolygonText,
} from "../../../utils";
import styles from "./RunDetails.module.css";
import Map from "../../components/Map";
import toast from "react-hot-toast";
import RunDevices from "../RunDevices";
import { addRoute, selectRouteById } from "../../../store/runSlice";
import { cliparttractor } from "../../../images";

function getColor(value) {
  switch (value) {
    case "Completed":
      return "text-green-200";
    case "Syncing":
      return "text-blue-900";
    case "Running":
      return "text-yellow-600";

    default:
      return "text-white";
  }
}
/* 
const blocks = [
  {
    name: "North East",
    locationId: 199,
    boundaries:
      "POLYGON ((25.774 -80.19, 18.466 -66.118, 32.321 -64.757, 25.774 -80.19))",
    image: "",
    id: 2,
    clientId: 1,
    rowLoadedUtc: "2024-03-28T21:40:06.44341",
    rowUpdatedUtc: "2024-03-28T21:40:06.44341",
  },
  {
    name: "Unknown",
    locationId: 1,
    boundaries: "",
    image: "",
    id: 5,
    clientId: 1,
    rowLoadedUtc: "2024-04-05T21:18:05.814024",
    rowUpdatedUtc: "2024-04-05T21:18:05.814024",
  },
  {
    name: "North West",
    locationId: 1,
    boundaries:
      "POLYGON ((40.7128 -74.0060,  34.0522 -77.1945, 43.1939 -71.5724))",
    image: "",
    id: 3,
    clientId: 1,
    rowLoadedUtc: "2024-03-28T21:41:02.458316",
    rowUpdatedUtc: "2024-03-28T21:41:02.458316",
  },
  {
    name: "South",
    locationId: 1,
    boundaries:
      "POLYGON ((40.7128 -74.0060,  34.0522 -118.2437, 51.5074 -0.1278))",
    image: "",
    id: 4,
    clientId: 1,
    rowLoadedUtc: "2024-03-28T21:44:57.016731",
    rowUpdatedUtc: "2024-03-28T21:44:57.01673",
  },
]; */

const Title = ({ content }) => (
  <div className={styles.break}>
    <span style={{ position: "relative" }}>
      {content}
      <ActionIcon
        style={{ pointerEvents: "all" }}
        variant="transparent"
        size="xs"
        radius="xl"
        aria-label="Settings"
        className="statcardTooltip"
      >
        <IconInfoCircleFilled
          style={{
            width: "85%",
            height: "85%",
            color: "#FFF",
          }}
          stroke={1.5}
        />
      </ActionIcon>
    </span>{" "}
    <span className={styles.invisible}>........</span>
  </div>
);

function formatHeartbeatStalenessTime(time) {
  if (!time) return;
  const split = time.split(":");
  if (!split || split.length != 2) return;
  const hours = String(Math.floor(split[0] / 60));
  const minutes = String(split[0] % 60);
  const seconds = String(split[1]);
  return `${hours}h ${minutes}m ${seconds}s`;
}

const RunDetails = () => {
  const { mutate } = useSWRConfig();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries: ["visualization"],
  });

  const { runId, runIndex, tractorId } = useParams();
  const { data, error, isLoading } = useSWR(`/api/runs?runId=${runId}`);
  const [subLocations, setSubLocations] = useState([]);
  const [locationData, setLocationData] = useState(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState("all");

  const validSubLocations = useMemo(
    () =>
      subLocations.filter((subLocation) =>
        data.subRuns.some((subRun) => subLocation.id === subRun.subLocationId)
      ),
    [data, subLocations]
  );

  console.log("validSubLocations", validSubLocations);

  const subLocationStats = useMemo(() => {
    if (!selectedSubLocation || !data) return;
    if (selectedSubLocation === "all") {
      return data.run;
    }

    return data.subRuns.find(
      (i) => String(i.subLocationId) === String(selectedSubLocation)
    );
  }, [selectedSubLocation, data]);

  const polygons = useMemo(() => {
    // if (!subLocationData || subLocationData.length === 0) return [];
    // return subLocationData
    // .map((subLocation) => getCoordinates(subLocation.boundaries))
    // .filter(Boolean);
    if (!subLocations) return [];

    if (selectedSubLocation === "all") {
      return subLocations
        .map((i) => ({
          name: i.name,
          paths: getCoordinatesFromPolygonText(i.boundaries),
        }))
        .filter(Boolean);
    } else {
      const foundBlock = subLocations.find(
        (i) => String(i.id) === String(selectedSubLocation)
      );
      if (!foundBlock) return [];
      return [
        {
          name: foundBlock.name,
          paths: getCoordinatesFromPolygonText(foundBlock?.boundaries),
        },
      ];
    }
  }, [selectedSubLocation, subLocations]);

  console.log("polygons", polygons);

  const [activeMarker, setActiveMarker] = useState(null);
  const navigate = useNavigate();

  const runDataFromRedux = useSelector(selectRouteById(runId));

  const [markerType, setMarkerType] = React.useState(
    runDataFromRedux?.markerType || "weedpressure-heatmap"
  );
  const handleRadioChange = (value) => {
    setMarkerType(value);
  };

  const markers = useMemo(() => {
    const result = [];

    for (let i = 0; i < data?.events?.length; i++) {
      result.push({
        id: i + 1,
        name: data.events[i].source + " " + data.events[i].type,
        images: data.events[i].relevantImages?.map((x) => x) || [],
        eventDateUtc: data.events[i].eventDateUtc,
        position: {
          lat: data.events[i].lat,
          lng: data.events[i].long,
        },
      });
    }

    return result;
  }, [data]);

  const route = useMemo(() => {
    if (!data?.route || data.route.length === 0) return [];

    const result = [];

    for (let i = 0; i < data.route.length; i++) {
      result.push({
        lat: data.route[i].lat,
        lng: data.route[i].long,
      });
    }

    return result;
  }, [data]);

  const flow = useMemo(() => {
    if (!data?.flowData || data.flowData.length === 0) return [];

    const result = [];

    for (let i = 0; i < data.flowData.length; i++) {
      result.push({
        lat: data.flowData[i].lat,
        lng: data.flowData[i].long,
      });
    }

    return result;
  }, [data]);

  useEffect(() => {
    if (!data) return;

    const fetchLocation = async () => {
      try {
        const token = await authService.getAccessToken();
        const headers = {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        };
        const promises = [
          fetch(
            `${process.env.REACT_APP_BASE_URL}/api/locations?locationId=${data.run.locationId}`,
            headers
          ),
          fetch(
            `${process.env.REACT_APP_BASE_URL}/api/sublocations?locationId=${data.run.locationId}`,
            headers
          ),
        ];

        const [err, [locationData, subLocationData]] = await to(
          Promise.all(promises)
        );
        if (err) return alert("There was an error!");

        setLocationData(await locationData.json());
        const newBlocks = await subLocationData.json();
        setSubLocations(newBlocks.filter((i) => i.boundaries));
        // setSubLocationData(await subLocationData.json());
      } catch (error) {}
    };
    fetchLocation();
  }, [data]);

  const getSubLocations = (id) => {
    alert(id);
  };

  const formattedTime = (time) => {
    if (!time) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h" + " " + splitTime[1] + "m";
  };

  const operatorName =
    data?.run?.operator?.split("@")?.[0] || data?.run?.operator || "Unknown";

  const handleFinish = async () => {
    if (!tractorId)
      return toast.error(
        "An unexpected error occured! Please go back, and select a tractor again!"
      );

    const token = await authService.getAccessToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/runsOperation/stop`,
        {
          method: "POST",
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
          body: JSON.stringify({
            tractorId,
          }),
        }
      );

      toast.success("Tractor run finished successfully!");
      mutate(`/api/runs?runId=${runId}`);
    } catch (err) {
      console.log(err);
      toast.error(
        "An unexpected error happened! Please check your internet connectivity and try again!"
      );
    }
  };

  const statData = useMemo(() => {
    return [
      {
        title: <Title content="Herbicide Saved" />,
        value: subLocationStats?.herbicideSavedPercent,
        subtitle: "percent",
        animate: true,
        tooltip: (
          <>
            <img
              src={cliparttractor}
              width={200}
              height={"auto"}
              style={{ margin: "0 auto" }}
            />
            This represents the difference between the amount of chemical
            sprayed using precision technology versus the amount that would have
            been sprayed on full broadcast at the supplied application rate.
          </>
        ),
      },
      {
        title: <Title content="Coverage Area" />,
        value: subLocationStats?.coverageAreaSprayedAcres,
        subtitle: "acres",
        animate: true,
        tooltip:
          "This represents the width of the potential spray area multiplied by the distance driven. The width of the potential spray area is calculated by summing the spray width of each nozzle.",
      },
      {
        title: <Title content="Application Rate" />,
        value: subLocationStats?.applicationRateGalAcre,
        subtitle: "gal / acre",
        animate: true,
        tooltip:
          "This is set in the settings of the sprayer.  It is used in the calculation of Herbicide sprayed.",
      },
      {
        title: <Title content="Total Area" />,
        value: subLocationStats?.totalAreaSprayedAcres,
        subtitle: "acres",
        animate: true,
        tooltip:
          "This represents the width of the boom multiplied by the distance driven.  The width of the boom is calculated from the furthest left part of the boom to the furthest right part of the boom.",
      },
      {
        title: <Title content="Herbicide Sprayed" />,
        value: subLocationStats?.totalPesticideSprayedGallons,
        subtitle: "gallons",
        animate: true,
        tooltip:
          "This is the actual amount of chemical sprayed as reported by the flow meter.",
      },
      {
        title: <Title content="Weed Detection" />,
        animate: false,
        value: subLocationStats?.weedCount,
        subtitle: "count",
        animate: true,
        tooltip:
          "This represents the approximate number of weed clusters detected.",
      },

      {
        title: <Title content="Speed" />,
        animate: false,
        value: subLocationStats?.avgSpeedMph,
        subtitle: "mph",
        animate: true,
        tooltip: "This represents the average speed of the entire run.",
      },

      {
        title: <Title content="Date" />,
        animate: true,
        tooltip: "This represents the date the run started.",
        value: `${
          data?.run?.startTimeUtc
            ? format(new Date(data?.run?.startTimeUtc), "M/d")
            : ""
        }`,
        subtitle: `${
          data?.run?.startTimeUtc
            ? format(new Date(data?.run?.startTimeUtc), "yyyy")
            : ""
        }`,
      },
      {
        title: <Title content="Duration" />,
        tooltip: "This represents the total amount of time of the run.",
        animate: true,
        value: formattedTime(subLocationStats?.duration),
        statSubtitleClassName: styles.statSubtitle,
        slotClassname: "margin-separation",
        subtitle: `${
          subLocationStats?.startTimeUtc
            ? format(new Date(subLocationStats?.startTimeUtc), "h:mmaaaaa")
            : ""
        } - ${
          subLocationStats?.endTimeUtc
            ? format(new Date(subLocationStats?.endTimeUtc), "h:mmaaaaa")
            : ""
        }`,
      },
      {
        title: "placeholder",
        className: styles.hiddenItem,
      },
    ];
  }, [subLocationStats]);

  const {
    data: deviceStatus,
  } = useSWR(`/api/tractors/device-status?tractorId=${tractorId}`);

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={isLoading}
          error={error}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />

        <section
          style={{ zIndex: 2 }}
          className="relative py-12 w-full pb-[7rem] run-details-section-container"
        >
          <div
            className={mergeClasses(
              "grid  grid-cols-[fit-content(10ch)_1fr] gap-[20px]",
              styles.gridContainer
            )}
          >
            <main style={{ zIndex: 10 }}>
              <div
                className={
                  styles.responsiveStatCard +
                  " max-w-xl lg:max-w-3xl run-details-container m-auto"
                }
                style={{ zIndex: 2 }}
              >
                <h1
                  className={
                    styles.title +
                    " text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl"
                  }
                >
                  Run # {data?.run?.displayId} Details -{" "}
                  {selectedSubLocation === "all"
                    ? "All blocks selected"
                    : `Block ${
                        subLocations.find(
                          (block) =>
                            String(block.id) === String(selectedSubLocation)
                        )?.name
                      }`}
                </h1>
                <div className="mt-4 mb-[50px]">
                  {data && data.run.runStatus === "Running" && (
                    <button
                      className="inline-block rounded border border-orange-600 bg-orange-600 px-12 py-3 text-md font-medium text-white hover:border-orange-800 hover:bg-orange-800 hover:text-white focus:outline-none  active:text-white ml-1 mb-6 active:bg-orange-900 active:border-orange-900 relative z-10 mt-2"
                      onClick={handleFinish}
                    >
                      Finish Run
                    </button>
                  )}
                <div className="messages">
                  {data && data.run.runStatus === "Running" && deviceStatus?.connected !== deviceStatus?.total ? (
                      <div className="text-[#c9c920]">
                    <span class="material-icons warning">warning</span>
                    <span>Connecting to devices ({deviceStatus.connected} of {deviceStatus.total} connected.)</span>
                  </div>
                  ): ''}
                </div>

                  <RunDevices />

                  <StatCard
                    className="run-details-top-statcard relative"
                    data={statData}
                  />

                  <StatCard
                    className="run-details-top-statcard mt-8 relative"
                    textAreaClassName={styles.textArea}
                    gridClassName={
                      subLocationStats?.runStatus != "Running"
                        ? ""
                        : styles.gridTwoFifty
                    }
                    data={[
                      {
                        title: <Title content="Operator" />,
                        animate: false,
                        tooltip: "This represents the driver of the run.",
                        value: operatorName,
                        valueClassName: styles.smallerWordWrap,
                        subtitle: locationData ? locationData.name : "...",
                        statSubtitleClassName: styles.statSubtitleClassName,
                        className: styles.smallPadding,
                      },
                      {
                        title: (
                          <div className={styles.break}>
                            <span style={{ position: "relative" }}>
                              Status
                              <ActionIcon
                                style={{ pointerEvents: "all" }}
                                variant="transparent"
                                size="xs"
                                radius="xl"
                                aria-label="Settings"
                                className="statcardTooltip"
                              >
                                <IconInfoCircleFilled
                                  style={{
                                    width: "85%",
                                    height: "85%",
                                    color: "#FFF",
                                  }}
                                  stroke={1.5}
                                />
                              </ActionIcon>
                            </span>{" "}
                            <span className={styles.invisible}>........</span>
                          </div>
                        ),
                        animate: false,
                        tooltip:
                          "This can be one of three values\n Running – The run is live, an operator is currently driving the field \n Syncing – The run has been finished, and now the system is uploading any outstanding run data \n Completed – All data has been successfully synced, and the run is now completed.",
                        valueClassName:
                          getColor(data?.run?.runStatus) +
                          " runDetailStatusStyle",
                        value: data?.run?.runStatus || "",
                        className: styles.smallPadding,
                      },

                      {
                        title: <Title content="Delay" />,
                        animate: false,
                        tooltip:
                          "This represents the amount of time from the last data update compared to the current time.",
                        value: formatHeartbeatStalenessTime(
                          data?.heartbeatStalenessTime
                        ),
                        valueClassName: styles.smallerWordWrap,
                        statSubtitleClassName: styles.statSubtitleClassName,
                        className: styles.smallPadding,
                      },

                      {
                        title: "placeholder",
                        className: styles.hiddenItem,
                      },
                    ]}
                  />

                  <StatCard
                    className="run-details-top-statcard mt-8 relative"
                    gridClassName={styles.statCardContainerTwo}
                    textarea={{
                      note: data?.run?.note,
                      runId,
                    }}
                    textAreaClassName={styles.textArea}
                    // noBokeh
                  />
                </div>
                <div className="mt-8 grid grid-cols-6 gap-6"></div>
              </div>
            </main>

            <aside>
              <div id="map-legend-container">
                <div id="block-legend" className="my-2">
                  <h5 className="font-bold text-center text-[24px] pb-1">
                    Select Block
                  </h5>
                  <select
                    value={selectedSubLocation}
                    onChange={(e) => setSelectedSubLocation(e.target.value)}
                    className="p-2 border border-gray-300 rounded custom-select"
                  >
                    <option value="all">All</option>
                    {validSubLocations.map((block) => (
                      <option key={block.id} value={block.id}>
                        {block.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div id="map-legend" className="my-2">
                  <h5 className="font-bold text-center text-[24px] pb-1">
                    Select Overlay
                  </h5>
                  <select
                    value={markerType}
                    onChange={(e) => setMarkerType(e.target.value)}
                    className="p-2 border border-gray-300 rounded custom-select"
                  >
                    <option value="marker-view">Markers</option>
                    <option value="weedpressure-heatmap">Weed Pressure</option>
                    <option value="spraydensity-heatmap">Spray Rate</option>
                    <option value="route-view">Route</option>
                  </select>
                </div>
              </div>

              {isLoaded ? (
                <Map
                  id={runId}
                  polygons={polygons}
                  baseUrl={data?.imageDetails?.baseUrl}
                  markers={markers}
                  route={route}
                  flow={flow}
                  markerType={markerType}
                  activeMarker={activeMarker}
                  setActiveMarker={setActiveMarker}
                  setMarkerType={setMarkerType}
                  isRunOver={data?.run?.endTimeUtc}
                  mapStyle={{ height: "950px" }}
                />
              ) : null}
            </aside>
          </div>
        </section>
      </div>
    </>
  );
};

export default RunDetails;
