import { format as datefnsFormat } from "date-fns";
import { enUS } from "date-fns/locale";
import { useState } from "react";
import { differenceInHours } from "date-fns";
import clsx from "clsx";

const locales = { enUS };

export const mergeClasses = (...classes) => {
  return clsx(classes);
};

export const format = (date, formatStr = "PP") => {
  if (!date) return "";

  const currentTimeZoneTime = new Date(
    new Date(date) - new Date().getTimezoneOffset() * 60000
  );

  return datefnsFormat(currentTimeZoneTime, formatStr, {
    locale: locales[window.__localeId__],
  });
};

export const useAnimateHook = (animation = "", timeout = 2000) => {
  const [animateClass, setAnimateClass] = useState("animate__animated");

  const triggerAnimation = () => {
    setAnimateClass(`animate__animated ${animation}`);
    setTimeout(() => {
      setAnimateClass(animateClass.replace(animation, ""));
    }, timeout);
  };

  return [animateClass, triggerAnimation];
};

export function truncateNumber(val, decimals = 1) {
  if (isNaN(val) && typeof val === "string") return val;
  if (isNaN(val) || !String(val)) return "";
  let parts = val.toString().split(".");
  if (parts.length === 1) return val;
  return parseFloat(parts[0] + "." + parts[1].substring(0, decimals));
}

export const formatDuration = (duration) => {
  if (!duration) return "";
  const [hours, minutes] = duration.split(":");
  return (
    <>
      <b>{hours} </b>
      <span>hours</span> <b>{minutes}</b>
      <span> min</span>
    </>
  );
};

export const fetcher = (url) => fetch(url).then((res) => res.json());

export function debounce(fn, wait) {
  let timer = null;
  return function (...params) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...params);
    }, wait);
  };
}

export function isMoreThan24Hours(date) {
  const hoursElapsed = differenceInHours(new Date(), date);
  return hoursElapsed > 24;
}

export function getCoordinatesFromPolygonText(data) {
  if (!data) return null;
  const re = /.*\(\((.*)\)\)/;
  const isolatedCoords = data.match(re)[1];
  const latlng = isolatedCoords.split(",");
  // console.log('latlng', latlng)

  return latlng.map((elt) => {
    const split = elt.trim().split(" ");
    return {
      lat: Number(split[1]),
      lng: Number(split[0]),
    };
  });
}

function wordToNumbers(word) {
  return word.split("").map((char) => char.charCodeAt(0));
}

export function generateRandomColor(word) {
  const num = wordToNumbers(word).reduce((acc, curr) => acc + curr, 0);

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = Math.abs(Number(num) * (i + 1) * 123456789) % 256;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}
