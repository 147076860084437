import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import authService from "../../api-authorization/AuthorizeService";
import RequestOverlay from "../../components/RequestOverlay";
import RadioTable from "../../components/RadioTable";
import styles from "./AdminConfigLocations.module.css";
import { useNavigate } from "react-router-dom";

const AdminConfigTractors = () => {
  const [clients, setClients] = useState(null);
  const [selectedTractor, setSelectedTractor] = useState(null);
  const [newTractorName, setNewTractorName] = useState("");
  const [newDevice, setNewDevice] = useState("");
  const [newDeviceCameraName, setNewDeviceCameraName] = useState("");

  const [client, setClient] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    // clientId: 1,
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const handleFormChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  const [tractors, setTractors] = useState([]);
  const [tractor, setTractor] = useState("");
  const [, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [showAddDevice, setShowAddDevice] = useState(false);

  const handleSelectTractor = (ids) => {
    setSelectedTractor(ids?.[0]);
  };

  const fetchClients = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/clients/all`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const clientData = await response.json();
      setClients(clientData);
      setLoading(false);
      setError(false);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchLocations = async () => {
    try {
      const token = await authService.getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/locations/all?ClientId=${client}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const tractorData = await response.json();
      setTractors(tractorData);
    } catch (error) {
      toast.error("Communication with server failed. Please reload your page.");
    }
  };

  useEffect(() => {
    if (!client) {
      setTractor("");
      return;
    }

    setTractor("");
    fetchLocations();
  }, [client]);

  const handleRemoveTractor = async (info) => {
    if (
      window.confirm(
        `Are you sure you want to remove location: ${info.row.original.name}?`
      )
    ) {
    } else {
    }
  };

  const columnsTractors = React.useMemo(
    () => [
      {
        id: "select",
        cell: ({ row }) => (
          <div className="px-1">
            <input
              type="radio"
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "name",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.name,
      },

      {
        accessorKey: "actions",
        cell: (info) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Link
              to={`/admin/locations/${info.row.original.id}`}
              style={{ textDecoration: "none" }}
            >
              Edit
            </Link>
            <button
              onClick={() => handleRemoveTractor(info)}
              style={{ marginLeft: "8px" }}
            >
              Remove
            </button>
          </div>
        ),
        header: (props) => <span>Actions</span>,
      },
    ],
    []
  );

  const handleAddLocation = async () => {
    if (
      !formData.name ||
      !formData.streetAddress ||
      !formData.city ||
      !formData.state ||
      !formData.zipCode ||
      !formData.country
    )
      return toast.error("Please complete all required fields.");
    const token = await authService.getAccessToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/locations/add`,
        {
          method: "POST",
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
          body: JSON.stringify({
            ...formData,
            clientId: parseFloat(client),

            // name: formData.name,
            // primaryContactEmail: formData.primaryContactEmail,
            // primaryContactPhone: formData.primaryContactPhone
          }),
        }
      );

      const data = await response.json();

      if (!data.added && data.message.includes("already exists")) {
        return toast.error("Location already exists for this client!");
      } else if (!data.added) {
        return toast.error(
          "Location was not added. Please check your data and try again!"
        );
      }

      toast.success("Location added successfully!");
      setFormData({
        name: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
      });

      fetchLocations();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={!clients}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />

        <section style={{ zIndex: 2 }} className="relative py-12 w-full pt-20">
          <div className="mb-4">
            <label className="pt-4 pb-2 font-extrabold">Client</label>
            <select
              // disabled={!!runInProgress}
              id="farm-client"
              name="farm-client"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300"
            >
              <option value="">-Select Client-</option>
              {clients?.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-2 grid-rows-1 gap-4">
            <div>
              <h1>Locations</h1>
              <RadioTable
                data={tractors}
                columns={columnsTractors}
                onSelect={handleSelectTractor}
              />
              {client !== "" && (
                <button
                  className={styles.configbutton}
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                  onClick={() => {
                    setShowAddLocation(!showAddLocation);
                  }}
                >
                  {showAddLocation
                    ? "Hide new location form"
                    : "New location form"}
                </button>
              )}

              {showAddLocation && (
                <div className="flex flex-col">
                  <label className="pt-4 pb-2 font-extrabold">Name</label>
                  <input
                    className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    value={formData.name}
                    onChange={handleFormChange("name")}
                  />
                  <label className="pt-4 pb-2 font-extrabold">
                    Street Address
                  </label>
                  <input
                    className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    value={formData.streetAddress}
                    onChange={handleFormChange("streetAddress")}
                  />
                  <label className="pt-4 pb-2 font-extrabold">City</label>
                  <input
                    className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    value={formData.city}
                    onChange={handleFormChange("city")}
                  />
                  <label className="pt-4 pb-2 font-extrabold">State</label>
                  <input
                    className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    value={formData.state}
                    onChange={handleFormChange("state")}
                  />
                  <label className="pt-4 pb-2 font-extrabold">Zip Code</label>
                  <input
                    className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    value={formData.zipCode}
                    onChange={handleFormChange("zipCode")}
                  />
                  <label className="pt-4 pb-2 font-extrabold">Country</label>
                  <input
                    className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                    value={formData.country}
                    onChange={handleFormChange("country")}
                  />
                  <div className="my-8">
                    <button
                      className={styles.configbutton}
                      onClick={handleAddLocation}
                    >
                      Add New Location
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminConfigTractors;
