import { Table } from "@mantine/core";

const maintenanceData = [
  {
    date: "6/31/23",
    location: "Farm A",
    tractor: "Big Bertha",
    issue: "broken solenoid",
  },
  {
    date: "6/30/23",
    location: "Farm B",
    tractor: "Harvest Hero",
    issue: "lost route signal",
  },
  {
    date: "6/29/23",
    location: "Farm C",
    tractor: "Weed Destroyer",
    issue: "need more herbicide",
  },
  {
    date: "6/28/23",
    location: "Farm D",
    tractor: "Traction Master",
    issue: "engine issue (no start)",
  },
];

function DataTable() {
  const rows = maintenanceData.map((maintenanceItem) => (
    <tr key={maintenanceItem.name}>
      <td>{maintenanceItem.date}</td>
      <td>{maintenanceItem.location}</td>
      <td>{maintenanceItem.tractor}</td>
      <td>{maintenanceItem.issue}</td>
      <td>{maintenanceItem.action}</td>
    </tr>
  ));

  return (
    <Table striped highlightOnHover withBorder withColumnBorders>
      <thead>
        <tr>
          <th>Date</th>
          <th>Location</th>
          <th>Tractor</th>
          <th>Issue</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export default DataTable;
