import { createSlice } from "@reduxjs/toolkit";

export const paginationSlice = createSlice({
  name: "app",
  initialState: {
    runPagesTracking: [], // { tractorId: 1, offset: 8 }, { tractorId: 2, offset: 1}
  },
  reducers: {
    setPage: (state, action) => {
      // Action: {tractorId: 1, page: 1}
      const found = state.runPagesTracking.findIndex(
        (i) => i.tractorId === action.payload.tractorId
      );

      if (found !== -1) {
        // tractorId was found
        state.runPagesTracking[found].page = action.payload.page;
      } else {
        // tractorId was not found
        state.runPagesTracking.push(action.payload);
      }
    },
  },
});

export const { setPage } = paginationSlice.actions;

export default paginationSlice.reducer;
