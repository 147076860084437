import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const AuthRoute = ({ requireAdmin, requireManager, children }) => {
  let location = useLocation();

  //   const user = useSelector((state) => state.app.user);
  const isManager = useSelector((state) => state.app.isManager);
  const isAdmin = useSelector((state) => state.app.isAdmin);

  if (requireAdmin && !isAdmin)
    return <Navigate to="/home" state={{ from: location }} replace />;
  if (requireManager && !isManager && !isAdmin)
    return <Navigate to="/home" state={{ from: location }} replace />;

  return children;
};

export default AuthRoute;
