import React, { useState, useEffect } from "react";
import useSWR from "swr";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import authService from "../../api-authorization/AuthorizeService";
import NavCard from "../../components/NavCard";
import InfoCard from "../../components/InfoCard";
import DetailCard from "../../components/DetailCard";
import MaterialInput from "../../components/MaterialInput";
import RequestOverlay from "../../components/RequestOverlay";
import RelButton from "../../components/RelButton";
import { addicon, tractoricon } from "../../../images/index";
import { fetcher } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./Tractors.module.css";
import { setLastClickedTractor } from "../../../store/appSlice";

const ITEMS_PER_PAGE = 8;

export default function Tractors() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lastClickedTractor = useSelector(
    (state) => state.app.misc.lastClickedTractor
  );
  const role = useSelector((state) => state.app.user.role);

  const [sortedData, setSortedData] = useState([]);
  const [search, setSearch] = useState("");
  const [forcedPage, setForcedPage] = useState(0);

  const { data, error, isLoading } = useSWR("api/tractors/all");
  console.log("data", data);
  console.log("lastClickedTractor", lastClickedTractor);

  useEffect(() => {
    console.log("Should sort!!!");
    if (!data || data.length === 0) return;
    console.log("Started sorting!!!");

    let filteredAndSortedData = data;

    if (search) {
      filteredAndSortedData = data.filter((tractor) => {
        return tractor.name.toLowerCase().includes(search.toLowerCase());
      });
    }

    filteredAndSortedData?.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (lastClickedTractor && a.id === lastClickedTractor) return -1;
      if (lastClickedTractor && b.id === lastClickedTractor) return 1;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    setSortedData(filteredAndSortedData);
  }, [data, lastClickedTractor, search]);

  console.log("sortedData", sortedData);

  // Pagination
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const currentItems = sortedData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(sortedData?.length / ITEMS_PER_PAGE) || 0;

  const handlePageClick = (event) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % sortedData.length;
    setItemOffset(newOffset);
    setForcedPage(event.selected);

    document
      .querySelector("#list-of-sprayers")
      .scrollIntoView({ behavior: "smooth", offset: 100 });
  };

  const handleSearch = (value) => {
    setSearch(value);
    const newOffset = (0 * ITEMS_PER_PAGE) % sortedData.length;
    setItemOffset(newOffset);
    setForcedPage(0);
  };

  return (
    <div className="mx-auto">
      <div className={styles.totalContainer}>
        <DetailCard
          className={styles.sticky}
          style={{ position: "sticky", top: "20px" }}
        />

        <div className={styles.listContainer} id="list-of-sprayers">
          <RequestOverlay
            isLoading={isLoading}
            error={error}
            id="loading-overlay"
            errorTitle="Your data couldn't be loaded."
            errorSubtitle="Please contact an administrator."
          />

          {/* <input
          className={
            styles.searchInputMobile +
            " mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
          }
          type="text"
          value={search}
          onChange={handleSearch}
          placeholder="Search for sprayer"
          /> */}

          <MaterialInput
            id={1}
            containerClassName={styles.materialInputFix}
            type="text"
            label="Lookup Sprayer"
            locked={false}
            active={false}
            value={search}
            onChange={handleSearch}
          />

          {currentItems && currentItems.length > 0
            ? currentItems.map((sprayer) => (
                <NavCard
                  key={sprayer.id}
                  containerStyle={
                    currentItems.length > 1
                      ? styles.linkContainerSingle
                      : styles.linkContainer
                  }
                  titleClassName={styles.titleClass}
                  onClick={async (e) => {
                    dispatch(setLastClickedTractor(sprayer.id));

                    if (role === "Operator") {
                      e.preventDefault();

                      try {
                        const activeRunResponse = await fetch(
                          `api/tractors/active-run?tractorId=${sprayer.id}`
                        );

                        const runData = await activeRunResponse.json();

                        // An active run was found
                        if (runData.isRunning) {
                          navigate(
                            `/precision-spray/${sprayer.name
                              .toLowerCase()
                              .replaceAll(" ", "-")}/${
                              sprayer.id
                            }/run-details/${runData.activeRun.id}/${
                              runData.activeRun.displayId
                            }`
                          );
                        } else {
                          // No active run was found
                          navigate(
                            `/precision-spray/${sprayer.name
                              .toLowerCase()
                              .replaceAll(" ", "-")}/${
                              sprayer.id
                            }/add-tractor-run`
                          );
                        }
                      } catch (error) {
                        toast.error("An error happened");
                      }
                    }
                  }}
                  link={`/precision-spray/${sprayer.name
                    .toLowerCase()
                    .replaceAll(" ", "-")}/${sprayer.id}`}
                  title={sprayer.name}
                  actionText="Sprayer details"
                  icon={sprayer.image}
                />
              ))
            : null}

          {/* {isAdmin && (
            <NavCard
              containerStyle={styles.linkContainer}
              link="/admin/tractors"
              title="Add Sprayer"
              actionText="&nbsp;"
              icon={addicon}
              style={{ backgroundColor: "none !important" }}
            />
          )} */}
        </div>

        <div />
        <div className={styles.paginateContainer}>
          <ReactPaginate
            forcePage={forcedPage}
            breakLabel="..."
            breakClassName="relative inline-flex items-center text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            breakLinkClassName="px-4 py-2"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
            pageLinkClassName="px-4 py-2"
            pageClassName="relative inline-flex items-center text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            activeClassName="bg-[#8dc34d] hover:bg-[#8dc34d] text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 text-black bg-white"
            activeLinkClassName="  bg-[#031832] text-white"
            nextLabel={<RelButton arrowDirection="right" />}
            previousLabel={<RelButton arrowDirection="left" />}
            disableInitialCallback
          />
        </div>
      </div>
    </div>
  );
}
