import { useState } from "react";
import styles from "./MaterialInput.module.css";

const ITEMS_PER_PAGE = 4;

const MaterialInput = ({
  locked,
  active,
  value,
  error,
  label,
  predicted,
  containerClassName,
  onChange,
}) => {
  const [activeInput, setActiveInput] = useState((locked && active) || false);
  const [valueInput, setValueInput] = useState(value || "");
  const [errorInput, setErrorInput] = useState(error || "");
  const [labelInput, setLabelInput] = useState(label || "Label");

  const changeValue = (event) => {
    const value = event.target.value;
    setValueInput(value);
    onChange(value);
    setErrorInput("");
  };

  const handleKeyPress = (event) => {
    if (event.which === 13) {
      setValueInput(predicted);
    }
  };

  const fieldClassName = `${styles.field} ${
    (locked ? activeInput : activeInput || valueInput) && styles.active
  } ${locked && !activeInput && styles.locked} ${containerClassName || ""}`;

  return (
    <div className={fieldClassName}>
      {activeInput && valueInput && predicted && predicted.includes(value) && (
        <p className={styles.predicted}>{predicted}</p>
      )}
      <input
        id={1}
        type="text"
        value={valueInput}
        placeholder={labelInput}
        onChange={changeValue}
        onKeyPress={handleKeyPress}
        onFocus={() => !locked && setActiveInput(true)}
        onBlur={() => !locked && setActiveInput(false)}
      />
      <label htmlFor={1} className={errorInput && styles.error}>
        {errorInput || labelInput}
      </label>
    </div>
  );
};

export default MaterialInput;
