import React, { useState, useEffect } from "react";
import { ImageSlide } from "../../components/ImageSlide";
import { List, ThemeIcon } from "@mantine/core";
import useSWR from "swr";
import { IconCircleCheck, IconCircleDashed } from "@tabler/icons-react";
import authService from "../../api-authorization/AuthorizeService";
import DataTable from "../../components/DataTable";
import styles from "./MyFarm.module.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

function generateRandomInteger(min, max) {
  return Math.floor(min + Math.random() * (max - min + 1));
}
function normalizeData(data, locations) {
  const COLORS = ["red", "blue", "green", "orange", "teal"];
  const labels = [...data.map((i) => i.labels)].flat();
  const toDate = (el) => new Date(el.split("/").reverse().join("-"));
  const sortedLabels = [
    ...new Set(labels.sort((a, b) => toDate(a) - toDate(b))),
  ];

  const newData = {
    labels: sortedLabels,
    datasets: [],
  };

  for (let i = 0; i < data.length; i++) {
    const dataSet = [];
    for (let j = 0; j < sortedLabels.length; j++) {
      const foundValueIndex = data[i].labels.findIndex(
        (label) => label === sortedLabels[j]
      );
      dataSet.push(foundValueIndex != -1 ? data[i].data[foundValueIndex] : 0);
    }

    newData.datasets.push({
      label: locations[i].name,
      data: dataSet,
      borderColor: COLORS[i],
      backgroundColor: COLORS[i],
    });
  }
  return newData;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => generateRandomInteger(-1000, 1000)),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => generateRandomInteger(-1000, 1000)),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const sortDates = (labels, timeGrouping) => {
  const toDate = (el) => new Date(el.split("/").reverse().join("-"));
  if (timeGrouping === "daily") {
    return [...new Set(labels.sort((a, b) => toDate(a) - toDate(b)))];
  } else if (timeGrouping === "weekly") {
    return [
      ...new Set(
        labels.sort((first, second) => {
          const a = first.split(" ")[3];
          const b = second.split(" ")[3];

          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        })
      ),
    ];
  } else if (timeGrouping === "monthly") {
    return [
      ...new Set(
        labels.sort((first, second) => {
          const a = first.split(" ")[2];
          const b = second.split(" ")[2];

          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        })
      ),
    ];
  } else return [...new Set(labels.sort((a, b) => a - b))];
};

function sanitizeData(data, timeGrouping = "daily") {
  // Magic happens here
  const COLORS = ["red", "blue", "green", "orange", "teal"];
  const labels = [...data.map((i) => i.labels)].flat();
  const sortedLabels = sortDates(labels, timeGrouping);

  const newData = {
    labels: sortedLabels,
    datasets: [],
  };

  // Iterate data one by one
  for (let i = 0; i < data.length; i++) {
    const dataSet = [];
    // Then iterate the sortedLabels to create the values
    for (let j = 0; j < sortedLabels.length; j++) {
      const foundValueIndex = data[i].labels.findIndex(
        (label) => label === sortedLabels[j]
      );
      dataSet.push(foundValueIndex != -1 ? data[i].data[foundValueIndex] : 0);
    }

    newData.datasets.push({
      label: data[i].name,
      data: dataSet,
      borderColor: COLORS[i],
      backgroundColor: COLORS[i],
    });
  }

  // The correct set of data to be displayed in the chart
  return newData;
}

function MyFarm() {
  // const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("all");
  const [chartData, setChartData] = useState(null);

  const [chartType, setChartType] = useState("");
  const [timeGrouping, setTimeGrouping] = useState("");
  // const [loadingLocations, setLoadingLocations] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(false);

  const {
    data: locations,
    error: locationsError,
    isLoading: loadingLocations,
  } = useSWR(`/api/locations/all`);

  useEffect(() => {
    if (!locations || locations.length === 0) return;
    setChartType("PesticideSprayed");
    setTimeGrouping("daily");
  }, [locations]);

  useEffect(() => {
    if (!locations || locations.length === 0 || !timeGrouping) return;
    const fetchChartData = async () => {
      setLoadingData(true);
      const token = await authService.getAccessToken();
      Promise.all(
        locations.map((location) =>
          fetch(
            `api/charts?ChartName=${chartType}&LocationId=${location.id}&ChartTimeGrouping=${timeGrouping}`
          ).then((res) => res.json())
        )
      ).then((data) => {
        setChartData(sanitizeData(data, timeGrouping));
        // setChartData(normalizeData(data, locations));
      });
    };
    fetchChartData();
  }, [locations, timeGrouping, chartType]);

  return (
    <>
      <div className={styles.container}>
        <div className="App">
          <div className="command-center-container">
            <h1 className="pt-4 pb-10 text-5xl text-[#031832]">
              Command Center
            </h1>
            <div className="pb-14">
              <label className="pt-4 pb-10 font-extrabold">Chart type</label>
              <br />
              <select
                name="chart-type"
                id="chart-type"
                value={chartType}
                onChange={(e) => setChartType(e.target.value)}
              >
                <option
                  key="empty"
                  value=""
                  disabled
                >{`---------------------------------Select Chart Type---------------------------------`}</option>

                <option value="PesticideSprayed">Herbicide Sprayed</option>
                <option value="TotalTrips">Total Number Trips</option>
                <option value="WeedsSprayed">
                  Total Number of Weeds Sprayed
                </option>
                <option value="TotalMiles">Total Miles</option>
              </select>
            </div>

            <div className="pb-14">
              <label className="pt-4 pb-2 font-extrabold">Location</label>
              <select
                id="farm-location"
                name="farm-location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300"
              >
                <option value="all">All</option>
                {locations?.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>
            <label className="pt-4 pb-2 font-extrabold">Time Grouping</label>
            <br />

            <select
              name="chart-grouping"
              id="chart-grouping"
              value={timeGrouping}
              onChange={(e) => setTimeGrouping(e.target.value)}
            >
              <option
                key="empty"
                value=""
                disabled
              >{`---------------------------------Select Time Group---------------------------------`}</option>

              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>

            <div className="pt-12">
              {chartData && (
                <Line
                  options={options}
                  data={
                    location === "all"
                      ? chartData
                      : {
                          labels: chartData.labels,
                          datasets: [
                            chartData.datasets[
                              locations.findIndex(
                                (l) => String(l.id) === String(location)
                              )
                            ],
                          ],
                        }
                  }
                />
              )}
              <div className="pt-12">
                <DataTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyFarm;
