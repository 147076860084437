import React from "react";
import styles from "./DetailCard.module.css";
import tractor from "../../../images/tractorreal.png";
import useSWR from "swr";

export default function DetailCard({
  title,
  actionText,
  icon,
  link,
  style,
  className,
}) {
  const { data, error, isLoading } = useSWR("/api/summary");

  return (
    <div
      className={
        styles.container +
        " " +
        className +
        " grid h-fit py-[24px] px-[17px] items-center	justify-center	 bg-white rounded-[15px] shadow border border-green-500"
      }
      style={style}
    >
      <div className={styles.imgContainer}>
        <img src={tractor} className={styles.img} />
      </div>

      <div
        className={
          styles.infoContainer +
          " bg-zinc-100 rounded-[15px] px-[26px] py-[24px]"
        }
      >
        <p className={styles.text}>
          Locations :{" "}
          <span className={styles.textSecondary}>
            {data?.numberOfLocations}
          </span>
        </p>
        <p className={styles.text}>
          Live Sprayers :{" "}
          <span className={styles.textSecondary}>{data?.numberOfTractors}</span>
        </p>
        <p className={styles.text}>
          Active Devices :{" "}
          <span className={styles.textSecondary}>{data?.numberOfDevices}</span>
        </p>
      </div>
    </div>
  );
}
