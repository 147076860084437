import React from "react";
import { useLocation, Link } from "react-router-dom";
import logo from "../../../images/Agtellio_logo_cropped.png";
import LoginAvatar from "../LoginAvatar";
import { useWindowSize } from "react-use";
import styles from "./Navbar.module.css";

export const joinClasses = (...classes) => {
  return classes.map((className) => styles[className]).join(" ");
};

export const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { pathname } = useLocation();
  const isHome =
    pathname === "/home" ||
    pathname === "" ||
    pathname === "#" ||
    pathname === "/"; // Check if on the home page

  const { width } = useWindowSize();

  React.useEffect(() => {
    if (width > 1165) setIsOpen(false);
  }, [width]);

  return (
    <header className={styles.navBar}>
      {/* lg+ */}
      <div
        className={
          " relative  shadow-[var(--navbar-box-shadow)]  bg-[var(--navbar-gradient)]"
        }
      >
        <div className="absolute inset-0" />
        <div className="relative px-4 mx-auto sm:px-6 navbarlg:px-8">
          <nav className="flex items-center justify-between h-16 navbarlg:h-20 flex-row-reverse navbarlg:flex-row">
            <div className="block navbarlg:hidden">
              <LoginAvatar loggedIn />
            </div>

            <div className="flex-shrink-0">
              <a href="#" title="" className="flex">
                <img
                  className="navbar-agtellio-logo"
                  src={logo}
                  alt=""
                  width={115}
                  height={50}
                />
              </a>
            </div>

            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              id="hamburger"
              type="button"
              className={`inline-flex p-2 text-white transition-all duration-200 rounded-md navbarlg:hidden focus:bg-gray-800 hover:bg-gray-800 + " " + ${
                !isHome ? "" : "opacity-0 pointer-events-none"
              } `}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
            {!isHome && (
              <div className="hidden navbarlg:flex navbarlg:items-center space-x-4  navbarsmallelts:space-x-10   ">
                <Link
                  to="/precision-spray"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px] font-medium text-white"
                >
                  Spray
                </Link>
                <Link
                  to="/command-center"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                >
                  Command
                </Link>
                <Link
                  to="/aerial-spray-drone"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                >
                  Drone
                </Link>
                <Link
                  to="/automation"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                >
                  Automation
                </Link>
                <Link
                  to="/savings"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                >
                  Savings
                </Link>
                <Link
                  to="/climate"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                >
                  Climate
                </Link>

                <Link
                  to="/tech-support"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                >
                  Support
                </Link>

                <Link
                  to="/agronomist"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                >
                  Agronomist
                </Link>

                <Link
                  to="/to-do-list"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white whitespace-nowrap"
                >
                  To Do{" "}
                </Link>
                <a
                  target="_blank"
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                  href="https://share.hsforms.com/1wRQpAhgdS9aIsiOHaL0xbAdumbq"
                >
                  Quote
                </a>

                <Link
                  to="/weather"
                  title=""
                  className="text-[12px] navbarsmallelts:text-[15px]  font-medium text-white"
                >
                  Weather
                </Link>
              </div>
            )}
            <div
              className="items-center justify-center hidden px-6 py-3 text-base font-semibold text-black transition-all duration-200 navbarlg:inline-flex "
              // style={{ alignSelf: "end", paddingBottom: "8px" }}
            >
              <LoginAvatar loggedIn />
            </div>
          </nav>
        </div>
      </div>
      {/* xs to lg */}
      <nav
        className={`flex flex-col justify-between px-4 py-10 sm:px-6 transition-all origin-top navbarlg:hidden ${
          isOpen
            ? "scale-y-100 opacity-100 visible "
            : "scale-y-0 opacity-0 invisible h-0 !p-0"
        }`}
      >
        {/*    <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="inline-flex p-2 text-white transition-all duration-200 rounded-md focus:bg-gray-800 hover:bg-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button> */}
        <div className="flex flex-col flex-grow h-full">
          <nav className="flex flex-col flex-1 mt-4 space-y-2">
            <Link
              to="/precision-spray"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Spray{" "}
            </Link>
            <Link
              to="/command-center"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Command{" "}
            </Link>
            <Link
              to="/aerial-spray-drone"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Drone{" "}
            </Link>
            <Link
              to="/automation"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Automation{" "}
            </Link>
            <Link
              to="/savings"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Savings{" "}
            </Link>
            <Link
              to="/climate"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Climate{" "}
            </Link>
            <Link
              to="/tech-support"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Support{" "}
            </Link>
            <Link
              to="/agronomist"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Agronomist{" "}
            </Link>
            <Link
              to="/to-do-list"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              To Do{" "}
            </Link>
            <a
              target="_blank"
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
              href="https://share.hsforms.com/1wRQpAhgdS9aIsiOHaL0xbAdumbq"
            >
              Quote
            </a>
            <Link
              to="/weather"
              title=""
              className="flex w-full py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70"
            >
              {" "}
              Weather{" "}
            </Link>
          </nav>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
