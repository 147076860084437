import React, { useEffect, useRef, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import toast from "react-hot-toast";
import authService from "../../api-authorization/AuthorizeService";
import LoadingSpinner from "../../components/LoadingSpinner";
import RequestOverlay from "../../components/RequestOverlay";
import styles from "./AdminConfigDiagnostics.module.css";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  HeatmapLayer,
  Polyline,
} from "@react-google-maps/api";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "400px",
  zIndex: 50,
  borderRadius: "25px",
};

const AdminConfigDiagnostics = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries: ["visualization"],
  });

  const [clients, setClients] = useState(null);
  const [selectedTractor, setSelectedTractor] = useState(null);

  const [diagnosticImage, setDiagnosticImage] = useState({});
  const [diagnosticSyncRuns, setDiagnosticSyncRuns] = useState({});
  const [diagnosticsReportStatus, setDiagnosticsReportStatus] = useState({});
  const [diagnosticsGetLocation, setDiagnosticsGetLocation] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const [client, setClient] = useState("");
  const [tractors, setTractors] = useState([]);
  const [, setLoading] = useState(true);
  const [, setError] = useState(false);
  const code = useRef(null);
  const [numImagesToCapture, setNumImagesToCapture] = useState(3);
  const [delayBetweenCapturesMs, setDelayBetweenCapturesMs] = useState(1000);

  const [diagnosticImageLoading, setDiagnosticImageLoading] = useState(false);
  const [diagnosticSyncRunsLoading, setDiagnosticSyncRunsLoading] =
    useState(false);
  const [diagnosticsGetLocationLoading, setDiagnosticsGetLocationLoading] =
    useState(false);

  const [diagnosticsSyncRunsError, setDiagnosticsSyncRunsError] = useState("");
  const [diagnosticsGetLocationError, setDiagnosticsGetLocationError] =
    useState("");
  const [diagnosticImageError, setDiagnosticImageError] = useState("");
  const [diagnosticsReportStatusError, setDiagnosticsReportStatusError] =
    useState("");

  const [diagnosticsReportStatusLoading, setDiagnosticsReportStatusLoading] =
    useState("false");
  const captureImagesIntervalRef = useRef();
  const diagnosticSyncRunsIntervalRef = useRef();
  const diagnosticsReportStatusIntervalRef = useRef();
  const getLocationIntervalRef = useRef();

  // Map info
  const [zoom, setzoom] = useState(4);
  const [center, setCenter] = React.useState({ lat: 37.851, lng: -98.205 });
  console.log("center", center);
  const mapRef = React.useRef(null);
  const [map, setMap] = React.useState(null);

  // This means that we did receive proper data from the server
  // Then we interrupt
  useEffect(() => {
    console.log("clear interval called!");
    if (diagnosticImage.completed) {
      clearInterval(captureImagesIntervalRef.current);
      setDiagnosticImageLoading(false);
    }
  }, [diagnosticImage]);

  useEffect(() => {
    console.log("clear interval called!");
    if (diagnosticSyncRuns.completed) {
      clearInterval(diagnosticSyncRunsIntervalRef.current);
      setDiagnosticSyncRunsLoading(false);
    }
  }, [diagnosticSyncRuns]);

  useEffect(() => {
    console.log("clear interval called!");
    if (diagnosticsGetLocation.completed) {
      clearInterval(getLocationIntervalRef.current);
      setDiagnosticsGetLocationLoading(false);
      setDiagnosticsGetLocationError("");
    }
  }, [diagnosticsGetLocation]);

  useEffect(() => {
    console.log("clear interval called!");
    if (diagnosticsReportStatus.completed) {
      console.log("cleared!!!!", diagnosticsReportStatus);
      clearInterval(diagnosticsReportStatusIntervalRef.current);
      setDiagnosticsReportStatusLoading(false);
    }
  }, [diagnosticsReportStatus]);

  useEffect(() => {
    return () => {
      clearInterval(captureImagesIntervalRef.current);
      clearInterval(diagnosticSyncRunsIntervalRef.current);
      clearInterval(diagnosticsReportStatusIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    setDiagnosticImageLoading(false);
    setDiagnosticImage([]);
    setDiagnosticSyncRuns([]);
    setDiagnosticSyncRunsLoading(false);
    setDiagnosticsReportStatusLoading(false);
    setDiagnosticsReportStatus([]);

    clearInterval(captureImagesIntervalRef.current);
    clearInterval(diagnosticSyncRunsIntervalRef.current);
    clearInterval(diagnosticsReportStatusIntervalRef.current);
  }, [selectedTractor]);

  const handleSelectTractor = (e) => {
    setSelectedTractor(e.target.value);
  };

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const token = await authService.getAccessToken();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/admin/clients/all`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
          }
        );
        const clientData = await response.json();
        setClients(clientData);
        setLoading(false);
        setError(false);
      } catch (error) {
        console.log("error", error);
        setTimeout(() => {
          setError(true);
          setLoading(false);
        }, 1000);
      }
    };
    fetchClients();
  }, []);

  const fetchTractors = async () => {
    try {
      const token = await authService.getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/all?ClientId=${client}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const tractorData = await response.json();
      setTractors(tractorData);
      console.log("selectedTractor", selectedTractor);
    } catch (error) {
      toast.error("Communication with server failed. Please reload your page.");
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchTractors();
  }, [client]);

  useEffect(() => {
    const tm = setTimeout(() => {
      if (!clients) {
      }
    }, 8000);

    return () => clearTimeout(tm);
  }, [clients]);

  const handleRemoveTractor = async (info) => {
    console.log("info", info);

    if (
      window.confirm(
        `Are you sure you want to remove sprayer: ${info.row.original.name}?`
      )
    ) {
      console.log("disabled!");
    } else {
      console.log("cancelled!");
    }
  };

  const handleOnLoad = (map) => {
    // try {
    //   setMap(map);
    //   /* eslint-disable */
    //   const bounds = new google.maps.LatLngBounds();
    //   markers.forEach(({ position }) => bounds.extend(position));
    // } catch (error) {}
  };

  const handleCaptureImages = async () => {
    const storedNumImages = numImagesToCapture;
    if (
      !client ||
      !selectedTractor ||
      !numImagesToCapture ||
      !delayBetweenCapturesMs
    )
      return;
    setDiagnosticImageLoading(true);
    setDiagnosticImageError("");
    const token = await authService.getAccessToken();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/diagnostics/capture-images`, {
      method: "POST",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify({
        clientId: client,
        tractorId: selectedTractor,
        numImagesToCapture: numImagesToCapture,
        delayBetweenCapturesMs: delayBetweenCapturesMs,
      }),
    })
      .then((res) => res.json())
      .then(async (data) => {
        toast.success("Command sent to device");
        code.current = data.code;

        captureImagesIntervalRef.current = setInterval(async () => {
          try {
            const diagnostics = await fetch(
              //`${process.env.REACT_APP_BASE_URL}/api/diagnostics?clientId=1&code=d1aabcb2-411f-42d0-a0d5-cf1e4333e093`
              `${process.env.REACT_APP_BASE_URL}/api/diagnostics/capture-images?clientId=${client}&code=${data.code}&numImagesToCapture=${storedNumImages}`
            );
            const jsonData = await diagnostics.json();
            console.log("jsonData", jsonData);
            setDiagnosticImage(jsonData);
          } catch (error) {
            clearInterval(captureImagesIntervalRef.current);
            setDiagnosticImageLoading(false);
            setDiagnosticImageError("There was an error.");
          }
        }, 5000);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("error");
        setDiagnosticImageLoading(false);
      });
  };

  const handleReportStatus = async () => {
    if (!client || !selectedTractor) return;
    setDiagnosticsReportStatusLoading(true);
    setDiagnosticsReportStatusError("");
    const token = await authService.getAccessToken();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/diagnostics/report-status`, {
      method: "POST",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify({
        clientId: client,
        tractorId: selectedTractor,
      }),
    })
      .then((res) => res.json())
      .then(async (data) => {
        toast.success("Command sent to device");
        code.current = data.code;

        diagnosticsReportStatusIntervalRef.current = setInterval(async () => {
          try {
            const diagnostics = await fetch(
              //`${process.env.REACT_APP_BASE_URL}/api/diagnostics?clientId=1&code=901525d2-a8df-4b30-a8d7-d073a374399c`
              `${process.env.REACT_APP_BASE_URL}/api/diagnostics/report-status?clientId=${client}&code=${data.code}`
            );
            const jsonData = await diagnostics.json();
            console.log("interval called in fn");
            console.log("jsonData", jsonData);
            setDiagnosticsReportStatus(jsonData);
          } catch (error) {
            clearInterval(diagnosticsReportStatusIntervalRef.current);
            setDiagnosticsReportStatusLoading(false);
            setDiagnosticsReportStatusError("There was an error.");
          }
        }, 5000);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("error");
        setDiagnosticsReportStatusLoading(false);
      });
  };

  const handleSyncRuns = async () => {
    if (!client || !selectedTractor) return;
    setDiagnosticSyncRunsLoading(true);
    setDiagnosticsSyncRunsError("");
    const token = await authService.getAccessToken();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/diagnostics/sync`, {
      method: "POST",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify({
        clientId: client,
        tractorId: selectedTractor,
      }),
    })
      .then((res) => res.json())
      .then(async (data) => {
        toast.success("Command sent to device");
        code.current = data.code;

        diagnosticSyncRunsIntervalRef.current = setInterval(async () => {
          try {
            const diagnostics = await fetch(
              //`${process.env.REACT_APP_BASE_URL}/api/diagnostics?clientId=1&code=a2b9baa0-bdb3-4dee-be93-b5adfe11a3f1`
              // `${process.env.REACT_APP_BASE_URL}/api/diagnostics/sync?clientId=${client}&code=${data.code}`
              `${process.env.REACT_APP_BASE_URL}/api/diagnostics/sync?clientId=${client}&code=${data.code}`
            );
            const jsonData = await diagnostics.json();
            setDiagnosticSyncRuns(jsonData);
          } catch (error) {
            clearInterval(diagnosticSyncRunsIntervalRef.current);
            setDiagnosticSyncRunsLoading(false);
            setDiagnosticsSyncRunsError("There was an error.");
          }
        }, 5000);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("error");
        setDiagnosticSyncRunsLoading(false);
      });
  };

  const handleGetLocation = async () => {
    if (!client || !selectedTractor) return;
    setDiagnosticsGetLocationLoading(true);
    setDiagnosticsGetLocationError("");
    const token = await authService.getAccessToken();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/diagnostics/get-location`, {
      method: "POST",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify({
        clientId: client,
        tractorId: selectedTractor,
      }),
    })
      .then((res) => res.json())
      .then(async (data) => {
        toast.success("Command sent to device");
        code.current = data.code;

        let numberOfAttempts = 0;
        try {
          setTimeout(() => {
            getLocationIntervalRef.current = setInterval(async () => {
              console.log("Interval running!");
              const diagnostics = await fetch(
                //`${process.env.REACT_APP_BASE_URL}/api/diagnostics?clientId=1&code=901525d2-a8df-4b30-a8d7-d073a374399c`
                `${process.env.REACT_APP_BASE_URL}/api/diagnostics/get-location?clientId=${client}&code=${data.code}`
              );
              const jsonData = await diagnostics.json();
              // setDiagnosticsGetLocation(jsonData);
              const coords = jsonData?.data[0]?.message?.split(",");
              if (coords && coords[0] != "0" && coords[1] != "0") {
                numberOfAttempts = 0;
                if (jsonData.completed) {
                  clearInterval(getLocationIntervalRef.current);
                  setDiagnosticsGetLocationLoading(false);
                }
                setDiagnosticsGetLocation(jsonData);
                setCenter({
                  lat: parseFloat(coords[0]),
                  lng: parseFloat(coords[1]),
                });

                setzoom(15);
              } else {
                if (numberOfAttempts >= 2) {
                  setDiagnosticsGetLocationLoading(false);
                  setDiagnosticsGetLocationError(
                    "The request never fulfilled!!"
                  );
                  return clearInterval(getLocationIntervalRef.current);
                }
                return numberOfAttempts++;
              }

              setDiagnosticsGetLocationLoading(false);
            }, 5000);
          }, 1000);
        } catch (error) {
          setDiagnosticsGetLocationLoading(false);
          setDiagnosticsGetLocationError("There was an error.");
          clearInterval(getLocationIntervalRef.current);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("error");
        setDiagnosticsGetLocationLoading(false);
      });
  };

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={!clients}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />

        <section style={{ zIndex: 2 }} className="relative py-12 w-full pt-20">
          <div className="mb-4">
            <label className="pt-4 font-extrabold text-xl">Client:</label>
            <select
              // disabled={!!runInProgress}
              id="farm-client"
              name="farm-client"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300 mt-4"
            >
              <option value="">-Select Client-</option>
              {clients?.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>

          <div
            className="
                    grid grid-cols-1 grid-rows-1 gap-4"
          >
            <div className="overflow-x-auto">
              <span className="whitespace-nowrap">
                <h1 className="font-extrabold text-xl pb-4">Sprayers:</h1>
                {client ? (
                  <select
                    name="tractor"
                    value={selectedTractor}
                    onChange={handleSelectTractor}
                    className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300 mt-4"
                  >
                    <option value="">-Select Sprayer-</option>
                    {tractors && tractors.length
                      ? tractors?.map((i) => (
                          <option value={i.id}>{i.name}</option>
                        ))
                      : null}
                  </select>
                ) : (
                  <p className="text-center pt-4 pb-12 text-[rgba(128,128,128,0.8)]">
                    Select Client!
                  </p>
                )}
              </span>
              <hr className="style-two mt-8"></hr>

              <div className="flex flex-col">
                <hr className="style-two mb-6"></hr>

                <h1 className="text-center pt-10 pb-40 text-3xl bold underline">
                  Report Status
                </h1>

                <button
                  className={` inline-block rounded border border-blue-700 bg-blue-700 px-12 py-3 text-md font-medium text-white
                  hover:border-blue-800 hover:bg-blue-800 hover:text-white
                  focus:outline-none
                  active:text-white mr-5 active:bg-blue-900 active:border-blue-900 relative z-10 mt-2 mb-12
                   disabled:border-gray-400 disabled:bg-gray-400 disabled:text-white w-[210px] ${
                     diagnosticsReportStatusLoading && "disabled:bg-blue-800"
                   } min-w-[260px] whitespace-nowrap w-6/12 !mx-auto my-0 my-6`}
                  style={{ whiteSpace: "nowrap" }}
                  onClick={handleReportStatus}
                  disabled={
                    diagnosticsReportStatusLoading ||
                    !client ||
                    !selectedTractor
                  }
                >
                  {diagnosticsReportStatusLoading ? (
                    <LoadingSpinner />
                  ) : (
                    "Report Status"
                  )}
                </button>

                {diagnosticsReportStatusError && (
                  <p className="text-red-500 text-center p-2">
                    {diagnosticsReportStatusError}
                  </p>
                )}

                {diagnosticsReportStatus?.data?.length ? (
                  <table className="mb-20">
                    <tr>
                      <th>Device</th>
                      <th>Message</th>
                    </tr>
                    {diagnosticsReportStatus.data.map((i) => (
                      <tr>
                        <td> {i.serialNumber} </td>
                        <td>{i.message}</td>
                      </tr>
                    ))}
                  </table>
                ) : null}
              </div>

              <hr className="style-two mb-8"></hr>
              <h1 className="text-center pt-10 pb-40 text-3xl bold underline">
                Capture Images
              </h1>
              <div className="">
                <div className="image-capture-container">
                  <div className="whitespace-nowrap">
                    <label className="font-extrabold">
                      Number of images to capture:
                    </label>
                    <input
                      value={numImagesToCapture}
                      onChange={(e) => setNumImagesToCapture(e.target.value)}
                      type="number"
                      min={0}
                      className="ml-4 border border-gray-300 rounded px-2 py-1"
                      style={{ outline: "none" }}
                    />
                  </div>

                  <div className="whitespace-nowrap my-2">
                    <label className="font-extrabold">
                      Delay between captures (ms):
                    </label>
                    <input
                      value={delayBetweenCapturesMs}
                      onChange={(e) =>
                        setDelayBetweenCapturesMs(e.target.value)
                      }
                      type="number"
                      min={0}
                      className="ml-4 border border-gray-300 rounded px-2 py-1"
                      style={{ outline: "none" }}
                    />
                  </div>
                  <button
                    className={` inline-block rounded border border-blue-700 bg-blue-700 px-12 py-3 text-md font-medium text-white
                        hover:border-blue-800 hover:bg-blue-800 hover:text-white
                        focus:outline-none
                        active:text-white mr-5 active:bg-blue-900 active:border-blue-900 relative z-10 mt-6 mb-12
                         disabled:border-gray-400 disabled:bg-gray-400 disabled:text-white w-[210px] ${
                           diagnosticImageLoading && "disabled:bg-blue-800"
                         } min-w-[260px] whitespace-nowrap w-6/12 !mx-auto my-0 my-6`}
                    style={{ whiteSpace: "nowrap" }}
                    onClick={handleCaptureImages}
                    disabled={
                      diagnosticImageLoading ||
                      !client ||
                      !selectedTractor ||
                      !numImagesToCapture ||
                      !delayBetweenCapturesMs
                    }
                  >
                    {diagnosticImageLoading ? (
                      <LoadingSpinner />
                    ) : (
                      "Capture Images"
                    )}
                  </button>

                  {diagnosticImageError && (
                    <p className="text-red-500 text-center p-2">
                      {diagnosticImageError}
                    </p>
                  )}

                  {diagnosticImage?.data?.length ? (
                    <table className="mb-20">
                      <tr>
                        <th>Device</th>
                        <th>Message</th>
                        <th>Image</th>
                      </tr>
                      {diagnosticImage.data.map((i) => (
                        <tr>
                          <td> {i.serialNumber} </td>
                          <td>{i.message}</td>
                          <td>
                            <img src={i.image} />
                          </td>
                        </tr>
                      ))}
                    </table>
                  ) : null}
                </div>
              </div>

              <hr className="style-two mb-8"></hr>

              <div className="flex flex-col">
                <h1 className="text-center pt-10 pb-40 text-3xl bold underline">
                  Sync Runs
                </h1>
                <button
                  className={` inline-block rounded border border-blue-700 bg-blue-700 px-12 py-3 text-md font-medium text-white
                    hover:border-blue-800 hover:bg-blue-800 hover:text-white
                    focus:outline-none
                    active:text-white mr-5 active:bg-blue-900 active:border-blue-900 relative z-10 mt-8 mb-12
                     disabled:border-gray-400 disabled:bg-gray-400 disabled:text-white w-[210px] ${
                       diagnosticSyncRunsLoading && "disabled:bg-blue-800"
                     } min-w-[260px] whitespace-nowrap w-6/12 !mx-auto my-0 my-6`}
                  style={{ whiteSpace: "nowrap" }}
                  onClick={handleSyncRuns}
                  disabled={
                    diagnosticSyncRunsLoading || !client || !selectedTractor
                  }
                >
                  {diagnosticSyncRunsLoading ? <LoadingSpinner /> : "Sync Runs"}
                </button>
              </div>
              {diagnosticsSyncRunsError && (
                <p className="text-red-500 text-center p-2">
                  {diagnosticsSyncRunsError}
                </p>
              )}

              {diagnosticSyncRuns?.data?.length ? (
                <table className="mb-20">
                  <tr>
                    <th>Device</th>
                    <th>Message</th>
                  </tr>
                  {diagnosticSyncRuns.data.map((i) => (
                    <tr>
                      <td> {i.serialNumber} </td>
                      <td>{i.message}</td>
                    </tr>
                  ))}
                </table>
              ) : null}
              <hr className="style-two mb-8"></hr>
              <div class="flex flex-col">
                <h1 className="text-center pt-10 pb-40 text-3xl bold underline">
                  Get Location
                </h1>
                <button
                  className={` inline-block rounded border border-blue-700 bg-blue-700 px-12 py-3 text-md font-medium text-white
                    hover:border-blue-800 hover:bg-blue-800 hover:text-white
                    focus:outline-none
                    active:text-white mr-5 active:bg-blue-900 active:border-blue-900 relative z-10 mt-8 mb-12
                     disabled:border-gray-400 disabled:bg-gray-400 disabled:text-white w-[210px] ${
                       diagnosticsGetLocationLoading && "disabled:bg-blue-800"
                     } min-w-[260px] whitespace-nowrap w-6/12 !mx-auto my-0 my-6`}
                  style={{ whiteSpace: "nowrap" }}
                  onClick={handleGetLocation}
                  disabled={
                    diagnosticsGetLocationLoading || !client || !selectedTractor
                  }
                >
                  {diagnosticsGetLocationLoading ? (
                    <LoadingSpinner />
                  ) : (
                    "Get Location"
                  )}
                </button>
              </div>
              {diagnosticsGetLocationError && (
                <p className="text-red-500 text-center p-2">
                  {diagnosticsGetLocationError}
                </p>
              )}

              {isLoaded && (
                <GoogleMap
                  zoom={zoom}
                  // onZoomChanged={() => setzoom()}
                  // onDragEnd={handleDragEnd}
                  ref={mapRef}
                  options={{
                    streetViewControl: false,
                    scaleControl: true,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    // mapTypeId: google.maps.MapTypeId.SATELLITE,
                  }}
                  onLoad={handleOnLoad}
                  center={center}
                  mapContainerStyle={containerStyle}
                >
                  {diagnosticsGetLocation && <Marker position={center} />}
                </GoogleMap>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminConfigDiagnostics;
